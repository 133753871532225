import * as React from "react";
import { VisualContext } from "./common/visualContext";

export type PointerEvents = "auto" | "none";

export interface AppContextProps {
  pointerEvents: PointerEvents;
  update: () => void;
  updatePointerEvents: (event: PointerEvents) => void;
}

export interface VCXContextProps {
  vcx: VisualContext;
}

export interface SplitterContextProps {
  ratio: number;
  updateRatio: () => void;
}

export const AppContext = React.createContext<AppContextProps | null>(null);
export const VCXContext = React.createContext<VCXContextProps | null>(null);
export const SplitterContext = React.createContext<SplitterContextProps | null>(null);
