import * as React from "react";
import { CSNclTabControlMetadata, Orientation, UpdateTabControl } from "../../common/communication.base";
import { NclBaseTabControl, NclVRTabControl } from "../../common/components.ncl";
import { GenerateControl } from "../K2GenerateControl";
import css from "./TabContent.scss";

const K2TabContent = (props: { currentPage: string; control: NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl> }) => {
  const getClassName = () => {
    let className = css.tc_content;

    if (!(props.control instanceof NclVRTabControl)) {
      className += ` ${css.tc_padding}`;

      if (props.control.InEditMode) {
        className += ` ${css.tc_edit}`;
      } else {
        className += ` ${css.tc_read}`;
      }

      if (props.control.Ncl.ListDetailTabControl) {
        className += ` ${css.tc_no_border}`;
      }
    }

    if (props.control.getOrientation() === Orientation.foHorizontal) {
      className += ` ${css.tc_no_top_border}`;
    } else {
      className += ` ${css.tc_no_left_border}`;
    }

    return className;
  };

  const getCSS = (currentPage: boolean) => {
    const css: React.CSSProperties = {};

    if (!currentPage) {
      css.display = "none";
    }

    return css;
  };

  return (
    <div className={getClassName()}>
      {props.currentPage &&
        props.control.Pages.map((control) => {
          if (
            props.control instanceof NclVRTabControl ||
            control.Content instanceof NclVRTabControl ||
            control.Content.MetaData.ControlUID === props.currentPage
          ) {
            return (
              <React.Fragment key={control.MetaData.ControlUID + "." + control.key}>
                {GenerateControl(control.Content, getCSS(control.Content.MetaData.ControlUID === props.currentPage))}
              </React.Fragment>
            );
          }
        })}
    </div>
  );
};

export default K2TabContent;
