import React, { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { SvgStepEnd, SvgStepStart, SvgStepTransfer, SvgStepCase, SvgStepDistrib, SvgStepSubModel, SvgStepAcknowledge } from "./SvgStep";
import { NodeData, StepType } from "../../common/communication.base";

export default memo((props: NodeProps<NodeData>) => {
  let image: JSX.Element | undefined;

  switch (props.data.stepType) {
    case StepType.itStart:
      image = <SvgStepStart />;
      break;
    case StepType.itEnd:
      image = <SvgStepEnd />;
      break;
    case StepType.itTransfer:
      image = <SvgStepTransfer />;
      break;
    case StepType.itCase:
      image = <SvgStepCase />;
      break;
    case StepType.itDistrib:
      image = <SvgStepDistrib />;
      break;
    case StepType.itSubModel:
      image = <SvgStepSubModel />;
      break;
    case StepType.itAcknowledge:
      image = <SvgStepAcknowledge />;
      break;
    default:
      break;
  }

  return (
    <>
      <div className={"node-box"}>
        <Handle type="source" position={Position.Left} id="Center" className={"handleCaption"} isConnectable={false} />
        {image}
        {props.data.isConnectable && (
          <Handle type="source" id="Move" position={Position.Left} className={"handleStep"} isConnectable={props.data.isConnectable} />
        )}
      </div>
    </>
  );
});
