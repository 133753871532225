import React from "react";
import { UpdateHeadered } from "../../common/communication.base";
import { NclCodeReaderDialog } from "../../common/components.ncl";
import K2Button from "../Button/K2Button";
import { useServerState } from "../hooks";
import { WithContextPlacementProps } from "../k2hoc";
import css from "./CodeReader.scss";
import K2QRCodeReader from "./K2QRCodeReader";

const K2CodeReader = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclCodeReaderDialog, UpdateHeadered, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclCodeReaderDialog
  );

  return (
    <div className={css.cr} ref={element} data-k2-test-id={control.MetaData.Name}>
      <K2QRCodeReader control={control} />
      <div className={css.cr_buttons} style={{ height: `${control.VCX.InputControl.getInputHeight(3, true, false)}px` }}>
        <K2Button controlUID={control.OK.MetaData.ControlUID} vrUID={control.getRealizerUID()} fillSvg={true} />
        <K2Button controlUID={control.CANCEL.MetaData.ControlUID} vrUID={control.getRealizerUID()} fillSvg={true} />
      </div>
    </div>
  );
};

export default K2CodeReader;
