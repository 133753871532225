import React from "react";
import { isPDF } from "../../common/common";
import { UpdateImage } from "../../common/communication.base";
import { NclWebView } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import "./WebView.scss";

export default function K2WebView(props: WithContextPlacementProps) {
  const [control, data, element] = useServerState<NclWebView, UpdateImage, HTMLDivElement>(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclWebView);
  let content;

  if (isPDF(data.ObjectId)) {
    content = <object data={data.ObjectId} className="wv_object" />;
  } else {
    content = (
      <iframe
        src={data.ObjectId}
        className="wv_object wv_iframe"
        sandbox="allow-scripts allow-popups allow-modals allow-forms allow-same-origin allow-presentation"
        allowFullScreen
      />
    );
  }

  return <div style={StyleHelper(control, props.style)}>{data.ObjectId && content}</div>;
}
