import React from "react";
import { getAttributes } from "../../common/common";
import { Align, UpdateDataGrid } from "../../common/communication.base";
import { NclDataGrid, NclSplitterPanel, UFOverAlign } from "../../common/components.ncl";
import { K2Header } from "../Expander/K2Expander";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { K2MFList } from "../MultiFormatText/K2MultiFormatText";
import K2DataGridContent from "./K2DataGridContent";
import K2DataGridFooter from "./K2DataGridFooter";

const K2DataGrid = (props: WithContextPlacementProps) => {
  const [control, data, element, vcxVersion, focusData] = useServerState<NclDataGrid, UpdateDataGrid, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclDataGrid
  );
  control.addListenerProp({ getOverRect });

  function getOverRect() {
    if (control && control.Content && control.Content.DataGrid && control.Content.DataGrid.Listener) {
      const listener: UFOverAlign = control.Content.DataGrid.Listener;

      if (listener.getOverRect) {
        return listener.getOverRect();
      }
    }

    return null;
  }

  const style: React.CSSProperties = {
    ...props.style,
    minHeight: control.ComputedMinHeightWithMargin + "px",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div ref={element} className="dg" data-k2-test-id={control.Ncl.Name} style={StyleHelper(control, style)} {...getAttributes(data)} tabIndex={undefined}>
      {control.isShowHeader() && (
        <K2Header
          controlUID={control.Header.MetaData.ControlUID}
          vrUID={props.vrUID}
          title={data.Title}
          titleSuffix={data.TitleSuffix}
          titleSuffixCommandEnabled={data.TitleSuffixCommandEnabled}
        />
      )}
      {control.InstantFilterPanel && <K2MFList vrUID={control.getRealizerUID()} controlUID={control.InstantFilterPanel.MetaData.ControlUID} />}
      <K2DataGridContent controlUID={control.Content.MetaData.ControlUID} vrUID={props.vrUID} focusData={focusData} />
      {control.showFooter() && <K2DataGridFooter controlUID={control.Footer.MetaData.ControlUID} vrUID={control.getRealizerUID()} />}
    </div>
  );
};

export default K2DataGrid;
