import * as React from "react";
import { UpdateControl, UFUpdateControl } from "../../common/communication.base";
import { UFNclControlBase } from "../../common/components.ncl";
import { WithContextPlacementProps, K2ComponentState, StyleHelper, AcquireControl } from "../k2hoc";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./UndefinedControl.scss";

export class K2UndefinedControl extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2UndefinedControl`;
  private control: UFNclControlBase;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return true;
    }) as UFNclControlBase;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} className={css.uc}>
        <K2TruncateText>{"undefined control (" + this.control.MetaData.__type + ")"}</K2TruncateText>
      </div>
    );
  }
}
