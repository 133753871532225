import React, { useRef } from "react";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import Webcam from "react-webcam";
import { UFUpdateControl } from "../../common/communication.base";
import { NclWebcam } from "../../common/components.ncl";
import "./Webcam.scss";

const WIDTH = 1280;
const HEIGHT = 720;

const K2Webcam = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclWebcam, UFUpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclWebcam
  );
  const webcamRef = useRef<Webcam>(null);
  control.addListenerProp({ getScreenshot });

  const videoConstraints: boolean | MediaTrackConstraints = {
    facingMode: control.Ncl.FrgtData.UseFrontCamera ? "user" : "environment",
    width: WIDTH,
    height: HEIGHT,
  };

  function getScreenshot(): string | null | undefined {
    return webcamRef.current?.getScreenshot({ width: WIDTH, height: HEIGHT });
  }

  return (
    <div style={StyleHelper(control, props.style)} ref={element} className={`wc${control.Ncl.FrgtData.IsHidden ? " hidden" : ""}`}>
      <Webcam ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} className="wc_video" />
    </div>
  );
};

export default K2Webcam;
