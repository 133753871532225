import React, { useRef, useState, useEffect, useContext } from "react";
import css from "./FullScreenViewer.scss";
import K2Img from "../Image/K2Img";
import { Context, __ } from "../../appcontext";
import { VCXContext } from "../../context";

interface FullScreenProps {
  children: JSX.Element | null;
  setRef?: (ref: HTMLDivElement) => void;
  zoomAllowed: boolean;
  panAllowed: boolean;
  title: string;
}
export interface FullScreenViewerProps extends FullScreenProps {
  asModal?: false;
  fullScreenOption?: boolean;
}
export interface FullScreenModalProps extends FullScreenProps {
  asModal: true;
}

export const K2FullScreenViewer = (props: FullScreenViewerProps | FullScreenModalProps) => {
  const vcx = useContext(VCXContext);
  const content = useRef<HTMLDivElement>(null);
  const resizable = useRef<HTMLDivElement>(null);
  const [showAsModal, setShowAsModal] = useState<boolean>(props.asModal);
  const [translate, setTranslate] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [scale, _setScale] = useState<number>(1);
  const lastMousePosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const touchDistance = useRef<number>(0);
  const isMouseDown = useRef<boolean>(false);
  const isMouseInside = useRef<boolean>(false);
  const doubleClick = useRef<boolean>(false);

  useEffect(() => {
    window.addEventListener("wheel", handleWindowWheel, { passive: false }); // Wheel by šel dát přímo na element, ale to neplatí pro keydown. Tak ať pracují stejně.
    window.addEventListener("keydown", handleKeyDown, { capture: true });
    return () => {
      window.removeEventListener("wheel", handleWindowWheel);
      window.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, []);

  useEffect(() => {
    setTranslate({ x: 0, y: 0 });
    _setScale(1);
  }, [showAsModal]);

  function handleCloseClick() {
    setShowAsModal(false);
    if (Context.getApplication()?.fullScreenViewer) {
      Context.getApplication().fullScreenViewer.hide();
    }
  }

  function handleWindowWheel(e: WheelEvent) {
    if (!isMouseInside.current) return;
    e.preventDefault();
  }

  function handleKeyDown(this: Window, e: KeyboardEvent) {
    if (!isMouseInside.current) return;

    let scaleChange!: number;
    let translateChange!: { x: number; y: number };

    switch (e.key) {
      //Zoom in
      case "+":
      case "PageUp":
        scaleChange = 0.1;
        break;

      //Zoom out
      case "-":
      case "PageDown":
        scaleChange = -0.1;
        break;

      //Pan right
      case "ArrowRight":
        translateChange = { x: -20, y: 0 };
        break;
      //Pan left
      case "ArrowLeft":
        translateChange = { x: 20, y: 0 };
        break;
      //Pan Down
      case "ArrowDown":
        translateChange = { x: 0, y: -20 };
        break;
      //Pan Up
      case "ArrowUp":
        translateChange = { x: 0, y: 20 };
        break;

      //Ukončí vše nezachycené
      default:
        return;
    }

    e.preventDefault();
    e.stopImmediatePropagation();

    if (scaleChange) setScale(scaleChange);

    if (translateChange)
      setTranslate((prevTranslate) => {
        return { x: prevTranslate.x + translateChange.x, y: prevTranslate.y + translateChange.y };
      });
  }

  function handleWheel(e: React.WheelEvent<HTMLDivElement>) {
    if (!props.zoomAllowed) return;
    const scaleChange = e.deltaY > 0 ? -0.1 : 0.1; // Determine zoom direction
    setScale(scaleChange, { x: e.clientX, y: e.clientY });
  }
  function showFullScreenOption(): boolean {
    return "fullScreenOption" in props && props.fullScreenOption && !showAsModal;
  }

  function setScale(scaleDiff: number, cursorPosition?: { x: number; y: number }) {
    _setScale((prevScale) => {
      const newScale = Math.max(0.3, prevScale + scaleDiff);

      if (cursorPosition) {
        const scaleFactor = newScale / prevScale;
        adjustResizablePosition(cursorPosition, scaleFactor);
      }

      return newScale;
    });
  }
  function setScalePerc(scalePerc: number, cursorPosition?: { x: number; y: number }) {
    _setScale((prevScale) => {
      if (cursorPosition) {
        adjustResizablePosition(cursorPosition, scalePerc);
      }

      return Math.max(0.3, prevScale * scalePerc);
    });
  }

  function adjustResizablePosition(cursorPosition: { x: number; y: number }, scaleFactor: number) {
    if (!resizable.current) return;

    const boundingBox = resizable.current.getBoundingClientRect();
    const relativeCursorPosition = {
      // V závislosti na pozici prvku (levý horní roh)
      x: cursorPosition.x - boundingBox.left,
      y: cursorPosition.y - boundingBox.top,
    };
    let translatedX: number, translatedY: number;

    // Cursor je v prvku
    if (
      relativeCursorPosition.x > 0 &&
      relativeCursorPosition.y > 0 &&
      relativeCursorPosition.x < boundingBox.width &&
      relativeCursorPosition.y < boundingBox.height
    ) {
      translatedX = (relativeCursorPosition.x - boundingBox.width / 2) * (1 - scaleFactor);
      translatedY = (relativeCursorPosition.y - boundingBox.height / 2) * (1 - scaleFactor);
    } else {
      // Snaží se nastavit pozici na střed obrazovky
      const relativeCenterPosition = {
        x: window.innerWidth / 2 - boundingBox.left,
        y: window.innerHeight / 2 - boundingBox.top,
      };
      translatedX = -(relativeCenterPosition.x - boundingBox.width / 2) * -Math.abs(1 - scaleFactor);
      translatedY = -(relativeCenterPosition.y - boundingBox.height / 2) * -Math.abs(1 - scaleFactor);
    }

    setTranslate((prevTranslate) => {
      return { x: prevTranslate.x + translatedX, y: prevTranslate.y + translatedY };
    });
  }

  function handleMouseDown(e: React.MouseEvent) {
    if (e.button === 0) {
      lastMousePosition.current.x = e.clientX;
      lastMousePosition.current.y = e.clientY;
      isMouseDown.current = true;
    }
  }

  function handleMouseMove(e: React.MouseEvent) {
    if (!props.panAllowed && !props.zoomAllowed) return;
    if (isMouseDown.current) {
      const movement = {
        x: e.clientX - lastMousePosition.current.x,
        y: e.clientY - lastMousePosition.current.y,
      };
      setTranslate((lastTranslate) => {
        return { x: lastTranslate.x + movement.x, y: lastTranslate.y + movement.y };
      });
      lastMousePosition.current.x += movement.x;
      lastMousePosition.current.y += movement.y;
    }
  }

  function handleMouseUp(e: React.MouseEvent) {
    if (e.button === 0) {
      isMouseDown.current = false;
    }
  }

  function handleClickResizable(e: React.MouseEvent) {
    if (doubleClick.current) {
      let scaleChange: number;

      if (e.ctrlKey) scaleChange = -0.4;
      else scaleChange = 0.5; // Přibližovat je fajn rychleji

      setScale(scaleChange, { x: e.clientX, y: e.clientY });
    } else {
      doubleClick.current = true;
      setTimeout(() => {
        doubleClick.current = false;
      }, 300);
    }
  }

  function handleTouchEnd(e: React.TouchEvent<HTMLDivElement>) {
    if (e.touches.length > 0) {
      lastMousePosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }
  }

  function handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    //Incializace pozice prstu
    if (e.touches.length > 0) {
      lastMousePosition.current = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }

    //Inicializace vzdálenosti mezi prsty
    if (e.touches.length > 1) {
      const x = e.touches[0].clientX - e.touches[1].clientX;
      const y = e.touches[0].clientY - e.touches[1].clientY;

      touchDistance.current = Math.sqrt(x * x + y * y);
    }
  }

  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (!props.panAllowed && !props.zoomAllowed) return;
    const movement = {
      x: e.touches[0].clientX - lastMousePosition.current.x,
      y: e.touches[0].clientY - lastMousePosition.current.y,
    };
    setTranslate((lastTranslate) => {
      return { x: lastTranslate.x + movement.x, y: lastTranslate.y + movement.y };
    });
    lastMousePosition.current.x += movement.x;
    lastMousePosition.current.y += movement.y;

    if (e.touches.length > 1) {
      const x = e.touches[0].clientX - e.touches[1].clientX;
      const y = e.touches[0].clientY - e.touches[1].clientY;
      const newTouchDistance = Math.sqrt(x * x + y * y);
      const touchDistanceDiffPerc = newTouchDistance / touchDistance.current;

      setScalePerc(touchDistanceDiffPerc, {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      });

      touchDistance.current = newTouchDistance;
    }
  }

  // Dodělat Z-Index výpočet
  return (
    <>
      <div
        className={css.fsw}
        style={{ zIndex: showAsModal ? 999999 : undefined }}
        data-inline={showAsModal ? "0" : "1"}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseEnter={() => {
          isMouseInside.current = true;
        }}
        onMouseLeave={() => {
          isMouseInside.current = false;
          isMouseDown.current = false;
        }}
      >
        {showAsModal && (
          <div className={css.fsw_header}>
            <div className="fsw_title">{props.title}</div>
            <div className={css.fsw_close} onClick={handleCloseClick}>
              <K2Img glyphId={"wui*close"} height={vcx?.vcx.sizeMap(30)} width={vcx?.vcx.sizeMap(30)} />
            </div>
          </div>
        )}
        <div
          ref={(e) => {
            content.current = e;
            if ("setRef" in props && props.setRef) props.setRef(e);
          }}
          className={css.fsw_content}
          onWheel={handleWheel}
        >
          <div className="fsw_control">
            {showFullScreenOption() && (
              <div
                className="fsw_fullScreen"
                title={__("showFullScreen")}
                onClick={() => {
                  setShowAsModal(true);
                }}
              >
                {getFullIcon()}
              </div>
            )}

            {scale !== 1 && (
              <div
                className="fsw_zoom"
                title={__("resetToDefaultState")}
                onClick={() => {
                  _setScale(1);
                  setTranslate({ x: 0, y: 0 });
                }}
              >
                {Math.trunc(scale * 100)}%
              </div>
            )}
          </div>
          <div
            ref={resizable}
            className={css.resizable}
            onMouseDown={handleMouseDown}
            onTouchMove={handleTouchMove}
            onClick={handleClickResizable}
            style={{
              transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
              touchAction: !showAsModal && props.panAllowed ? "none" : undefined,
            }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export interface FullScreenProviderState {
  visible: boolean | undefined;
}

export class FullScreenProvider extends React.Component<{}, FullScreenProviderState> {
  private prop: FullScreenViewerProps | FullScreenModalProps;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    Context.getApplication().fullScreenViewer = this;

    this.prop = {
      children: null,
      title: null,
      asModal: true,
      zoomAllowed: true,
      panAllowed: true,
    };
  }

  public show(children: JSX.Element, title: string, asModal = true) {
    this.prop.children = children;
    this.prop.title = title;
    this.prop.asModal = asModal;
    this.setState({ visible: true });
  }

  public hide(): void {
    this.prop.children = null;
    this.prop.title = null;
    this.prop.asModal = true;
    this.setState({ visible: false });
  }

  public render() {
    if (this.state.visible) return <K2FullScreenViewer {...this.prop} />;
    else return;
  }
}

function getFullIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32">
      <path d="M29.414 26.586l-6.586-6.586-2.828 2.828 6.586 6.586-2.586 2.586h8v-8zM2.586 5.414l6.586 6.586 2.828-2.828-6.586-6.586 2.586-2.586h-8v8zM26.586 2.586l-6.586 6.586 2.828 2.828 6.586-6.586 2.586 2.586v-8h-8zM12 22.828l-2.828-2.828-6.586 6.586-2.586-2.586v8h8l-2.586-2.586z" />
    </svg>
  );
}
