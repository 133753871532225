import { List } from "immutable";
import React, { useEffect, useRef } from "react";
import { Context } from "../../appcontext";
import { CSNclViewMetadata, RectInDockSizeMode, UpdateHeadered } from "../../common/communication.base";
import { NclMenuView, NclOpenDialog, NclViewBase } from "../../common/components.ncl";
import { ViewRealizerManager } from "../../viewrealizer";
import { K2Header } from "../Expander/K2Expander";
import { AcquireControl, WithVCXProps } from "../k2hoc";
import { RealizerQueueItem } from "../View/ViewRealizerReact";
import css from "./MobileModal.scss";
import resolveContextMenu from "../../utils/resolveContextMenu";

interface SimpleModalWindowProps extends WithVCXProps {
  realizerUID: string; // realizer for show
  controlUID: string;
  isOverlayBck?: boolean;
  realizersQueue?: List<RealizerQueueItem>;
  headerTitle: string;
  updateModalList?: () => void;
}

let mobileVH: number;

const K2SimpleModalWindow = (props: SimpleModalWindowProps) => {
  const control = AcquireControl(props.controlUID, props.realizerUID, (ctrl) => ctrl instanceof NclViewBase) as NclViewBase<CSNclViewMetadata, UpdateHeadered>;
  const vr = ViewRealizerManager.getViewRealizer(props.realizerUID);
  const root = useRef<HTMLDivElement>(null);

  useEffect(() => {
    resolveContextMenu(root.current, handleContextMenu);
  }, [root]);

  if (!mobileVH) {
    mobileVH = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${mobileVH}px`);
  }

  const handleAnimationtionEnd = () => {
    props.realizersQueue?.map((r) => {
      if (!r.isOpen) {
        props.updateModalList?.();
      }
    });
  };

  useEffect(() => {
    if (!isAnimationDisabled()) return;

    props.realizersQueue?.map((r) => {
      if (!r.isOpen) {
        props.updateModalList?.();
      }
    });
  }, [props.realizersQueue]);

  const isAnimationDisabled = (): boolean => {
    return Context.DeviceInfo.IsAnimationDisabled;
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (control.getRectInDock().EffectiveMouseClickClose) {
      if (e.target === root.current) {
        //nevim jestli pro to není lepší způsob, prozatím takto
        control.closeRequest();
        if (e.type === "contextmenu") {
          e.preventDefault();
        }
      }
    }
  };

  const handleContextMenu = (e: MouseEvent) => {
    if (control.getRectInDock().EffectiveMouseClickClose) {
      if (e.target === root.current && control.isNotificationBox) {
        //nevim jestli pro to není lepší způsob, prozatím takto
        control.closeRequest();
        e.preventDefault();
      }
    }
  };

  const rect = control.getRectInDock();
  const isModalOrPercentSize = !vr.isModal() || (rect && rect.SizeMode === RectInDockSizeMode.ridsmPercent && (rect.Height > 0 || rect.Width > 0));
  const animationDisabled = isAnimationDisabled();
  let className = css.modal_mobile;
  const closingRealizer = props.realizersQueue?.find((r) => {
    if (
      (control.MetaData.ControlUID === r.controlUID && r.isOpen === false) || // zavira se realizer
      (r.controlUID == null && props.realizerUID === r.realizerUID && r.isOpen === false) // zavira se jen control na realizeru (napr. floater)
    ) {
      return true;
    }

    return false;
  });

  if (control.isMaximized() || control.isNotificationBox) {
    className += ` ${css.maximized}`;
  }

  if (control instanceof NclMenuView) {
    className += ` ${css.modal_mobile_menu_modal}`;
  } else if (control.isNotificationBox) {
    className += ` ${css.modal_mobile_notification}`;
  } else if (control instanceof NclOpenDialog) {
    className += ` ${css.modal_mobile_open_dialog}`;
  } else if (!isModalOrPercentSize) {
    if (vr.isDialog()) {
      className += ` ${css.modal_mobile_dialog}`;
    } else {
      className += ` ${css.modal_mobile_modal}`;
    }
  } else {
    className += ` ${css.modal_mobile_local_modal}`;
  }

  if (control.isMaximized()) {
    className += ` ${css.maximized}`;
  }

  if (!animationDisabled) {
    if (closingRealizer) {
      className += ` ${css.animation_close}`;
    } else {
      className += ` ${css.animation_open}`;
    }
  }

  return (
    <div
      ref={root}
      onClick={control.isNotificationBox ? handleOutsideClick : undefined}
      style={{
        zIndex: vr.getDepth() * 5,
        backgroundColor: props.isOverlayBck ? control.VCX.getRGBColor(control.VCX.Data.ColorMap.AlphaColor).toHTML(0.2) : undefined,
        flexDirection: vr.isDialog() ? (Context.DeviceInfo.IsAnimationDisabled ? "column-reverse" : "column") : "row",
      }}
      className={css.modal_mobile_backdrop}
    >
      <div
        onAnimationEnd={!animationDisabled ? handleAnimationtionEnd : undefined}
        className={className}
        style={{
          touchAction: "pinch-zoom !important",
          height: vr.isDialog() ? control.calcFullHeight() + "px" : undefined,
        }} // doplněn touch-action, aby bylo možné zoomovat modální okno(např. sestava pro podpis). Pro draggable doplní React automaticky touch-action: none, což bylo nutné přerazit.
      >
        {control.isShowHeader() && (
          <K2Header
            controlUID={control.Header.MetaData.ControlUID}
            vrUID={control.getRealizerUID()}
            title={props.headerTitle}
            className={"handle_" + control.MetaData.ControlUID}
          />
        )}
        {props.children}
      </div>
    </div>
  );
};

export default K2SimpleModalWindow;
