import React, { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { NodeData, NodeEdgeCursor } from "../../common/communication.base";

export default memo((props: NodeProps<NodeData>) => {
  let classes = "";
  if (props.data.cursor === NodeEdgeCursor.lfDirect) {
    classes += " cursorDirect";
  } else if (props.data.cursor === NodeEdgeCursor.lfCurved) {
    classes += " cursorCurved";
  } else if (props.data.cursor === NodeEdgeCursor.lfHorizontal) {
    classes += " cursorHorizontal";
  } else if (props.data.cursor === NodeEdgeCursor.lfVertical) {
    classes += " cursorVertical";
  }
  if (props.data.active) {
    classes += " active";
  }

  return (
    <>
      <div className={"node-box" + classes}>
        <Handle type="source" position={Position.Left} isConnectable={false} className={"handleEdge"} />
      </div>
    </>
  );
});
