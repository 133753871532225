import * as React from "react";
import { UpdateControl, UFUpdateControl } from "../../common/communication.base";
import { NclLibraryReference } from "../../common/components.ncl";
import { GenerateControl } from "../K2GenerateControl";
import { WithContextPlacementProps, K2ComponentState, StyleHelper, AcquireControl } from "../k2hoc";
import { getBackroundCss } from "../FlowPanel/K2FlowPanel";

export class K2LibraryReference extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  static displayName = `K2LibraryReference`;
  private control: NclLibraryReference;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclLibraryReference;
    }) as NclLibraryReference;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div
        style={StyleHelper(this.control, {
          ...this.props.style,
          background: "transparent",
          alignSelf: "stretch",
          minHeight: this.control.ComputedMinHeightWithMargin + "px",
          flexDirection: "column",
        })}
        className={getBackroundCss(this.control.isInPreview(), this.control.InEditMode)}
      >
        {this.control.Children.map((control) => {
          return GenerateControl(control, { flex: "1 1 auto" });
        })}
      </div>
    );
  }
}
