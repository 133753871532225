import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { getAttributes } from "../../common/common";
import { TDataTextFormat, UpdateFormattableInput } from "../../common/communication.base";
import { NclFormattableInput } from "../../common/components.ncl";
import { setRequestedActiveControl } from "../app";
import { useServerState } from "../hooks";
import K2Img from "../Image/K2Img";
import K2LabelInput from "../Input/K2LabelInput";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2ToolBar from "../ToolBar/K2ToolBar";
import { Editor } from "../WebContentEditor/K2WebContentEditor";
import css from "./FormattableInput.scss";

const K2FormattableInput = (props: WithContextPlacementProps) => {
  const [control, data, element, , focusData] = useServerState<NclFormattableInput, UpdateFormattableInput, HTMLTextAreaElement | HTMLIFrameElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFormattableInput
  );
  const [value, setValue] = useState(data.Text);
  const [focused, setFocused] = useState(false);
  const editorFrame = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setValue(data.Text);
  }, [data.Text]);

  useEffect(() => {
    // nefocusovat iframe podle ActiveControl, ale primo Segitor
    if (focusData.isFocused && data.TextFormat === TDataTextFormat.dtfHTML) {
      (editorFrame.current?.contentWindow?.document?.body?.firstElementChild as HTMLDivElement)?.focus();
    }
  }, [focusData.isFocused, data.TextFormat]);

  const handleChangeIframe = (name: string, value: string) => {
    control.change(value, true);
  };

  const isCodeEditorType = (): boolean => {
    return editorFrame.current?.contentWindow?.document?.body?.firstElementChild?.getAttribute("k2editortype") === "code";
  };

  const switchEditorType = () => {
    if (editorFrame.current) {
      (editorFrame.current.contentWindow as any).switchEditorType(!isCodeEditorType());
    }
  };

  const initRef = (el: HTMLIFrameElement) => {
    editorFrame.current = el;
    element.current = el;
  };

  const handleIframeFocus = (e: FocusEvent) => {
    handleFocus(e);
  };

  const handleIframeBlur = () => {
    setFocused(false);
  };

  const getHTML = () => {
    return (
      <div className={css.fi_html}>
        <Editor
          initRef={initRef}
          inEditMode={control.InEditMode}
          name={control.Ncl.Name}
          onChange={handleChangeIframe}
          text={data.Text}
          data={data}
          onFocus={handleIframeFocus}
          onBlur={handleIframeBlur}
        />
      </div>
    );
  };

  const getTextareaTag = () => {
    return (
      <textarea
        className={`${css.fi_textarea}`}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={element as React.MutableRefObject<HTMLTextAreaElement>}
        {...getAttributes(data)}
        value={value}
        id={control.Ncl.Name}
        rows={control.Size}
      />
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (
      e.key === "Enter" ||
      e.key === "Delete" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "z" ||
      e.key === "y" ||
      e.key === "-" ||
      e.key === "+" ||
      e.keyCode === 110 || // numpad decimal
      e.key.match(/^[0-9]$/) ||
      e.key === "Backspace"
    ) {
      e.stopPropagation();
    }
  };

  const handleFocus = (e: React.FocusEvent<any> | FocusEvent) => {
    e.stopPropagation();
    control.setActiveControlRequested();
    setFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<any>) => {
    setFocused(false);
    setRequestedActiveControl(e, control.getRealizerUID());
    changeData(value, true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const targetValue = e.target.value;

    if (value != targetValue) {
      setValue(targetValue);
      changeData(targetValue, false);
    }
  };

  const changeData = (data: string, accept: boolean) => {
    control.change(data, accept);
  };

  let content: JSX.Element = null;

  switch (data.TextFormat) {
    case TDataTextFormat.dtfText:
      content = getTextareaTag();
      break;
    case TDataTextFormat.dtfHTML:
      content = getHTML();
      break;
  }

  return (
    <div style={StyleHelper(control, props.style)} className={css.fi}>
      <K2LabelInput
        titleVisible={control.isVisibleTitle()}
        title={data.Title}
        errorsCount={null}
        warningsCount={null}
        focused={focused}
        inEditMode={control.InEditMode}
        modified={data.Modified}
        readOnly={data.ReadOnly}
        vcx={control.VCX}
        frgtData={control.Ncl.FrgtData}
        id={control.Ncl.Name}
        className={css.fi_label}
      >
        {(control?.ToolBar?.Actions?.length > 0 || (data.TextFormat === TDataTextFormat.dtfHTML && control.InEditMode)) && (
          <>
            <K2ToolBar
              key={control.ToolBar.MetaData.ControlUID}
              controlUID={control.ToolBar.MetaData.ControlUID}
              vrUID={props.vrUID}
              className={css.fi_toolbar}
            />

            {data.TextFormat === TDataTextFormat.dtfHTML && control.InEditMode && (
              <button type="button" className={`button ${css.fi_btn_switch}`} onClick={switchEditorType}>
                <K2Img
                  width={control.VCX.InputControl.getInputHeight(1, false, false)}
                  height={control.VCX.InputControl.getInputHeight(1, false, false)}
                  glyphId={isCodeEditorType() ? "wui*eye" : "wui*eye.no"}
                  vcx={control.VCX}
                  strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
                />
              </button>
            )}
          </>
        )}

        {content}
      </K2LabelInput>
    </div>
  );
};

export default K2FormattableInput;
