import React from "react";
import { Context } from "../../appcontext";
import { ColumnsProps } from "./utils";

const K2ColGroup = (props: ColumnsProps) => {
  return (
    <colgroup>
      {props.columnsProportion.map((value, i) => {
        return (
          <col
            data-k2-test-id={Context.DeviceInfo.IsTestMode === true ? value.FieldPath : undefined}
            key={`col__${i}`}
            style={{ width: `${props.columnsWidths[i]}px` }}
          />
        );
      })}
    </colgroup>
  );
};

export default K2ColGroup;
