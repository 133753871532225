import React from "react";
import { UpdateControl } from "../../common/communication.base";
import { NclDataGridFooter } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import K2ToolBar from "../ToolBar/K2ToolBar";
import css from "./DataGrid.scss";

const K2DataGridFooter = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclDataGridFooter, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclDataGridFooter
  );

  return (
    <div ref={element} style={StyleHelper(control, props.style)} className={css.dg_footer}>
      <K2ToolBar controlUID={control.LeftToolbar.MetaData.ControlUID} vrUID={control.getRealizerUID()} />
      <K2ToolBar controlUID={control.RightToolbar.MetaData.ControlUID} vrUID={control.getRealizerUID()} />
    </div>
  );
};

export default K2DataGridFooter;
