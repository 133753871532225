import React from "react";
import { NclControlBase } from "../../common/components.ncl";
import { VCXFontNoColor, VisualContext } from "../../common/visualContext";
import { WithContextPlacementProps } from "../k2hoc";
import { Context } from "../../appcontext";

export interface WithVCXinCSSProps extends WithContextPlacementProps {
  onVCXChanged?: (vcx: VisualContext, element: HTMLElement) => void;
}

export function withVCXInCSS<P extends WithVCXinCSSProps>(WrappedComponent: React.ComponentType<P>) {
  class ComponentWithVCXinCSS extends React.Component<P> {
    static displayName = `WithVCXinCSS(${WrappedComponent.displayName || WrappedComponent.name})`;

    render() {
      return <WrappedComponent {...this.props} onVCXChanged={this.handleVCXChanged} />;
    }

    private handleVCXChanged = (vcx: VisualContext, element: HTMLElement) => {
      if (vcx && element) {
        writeToCSS(vcx, element);
      }
    };
  }

  return ComponentWithVCXinCSS;
}

export function writeToCSS(vcx: VisualContext, element: HTMLElement, control?: NclControlBase) {
  if (vcx && element) {
    writePropertyToCSS((vcx.Zoom / 100) * Context.DeviceInfo.ZoomFactor, "Zoom", element, "");
    writePropertyToCSS(vcx.Data.MarginX, "MarginX", element, "");
    writePropertyToCSS(vcx.Data.MarginY, "MarginY", element, "");
    writePropertyToCSS(vcx.InputControl.getInputFrameWidth(), "FrameWidth", element, "InputControl");
    writePropertyToCSS(vcx.InputControl.getInputHeight(1, true, false), "Height", element, "InputControl");
    writePropertyToCSS(vcx.InputControl.getInputInteriorBorder(), "InteriorBorder", element, "InputControl");
    writePropertyToCSS(vcx.LabelControl.getHeight(control?.Size), "Height", element, "LabelControl");
    writePropertyToCSS(vcx.GridControl.GetHeaderRowHeight(), "HeaderRowHeight", element, "GridControl");
    writePropertyToCSS(vcx.GridControl.GetRowHeight(1), "RowHeight", element, "GridControl");
    writePropertyToCSS(vcx.ExpanderControl.GetHFHeight(), "HFHeight", element, "ExpanderControl");
    writePropertyToCSS(vcx.ExpanderControl.Data.LiteSeparationWidth, "LiteSeparationWidth", element, "ExpanderControl");
    writePropertyToCSS(vcx.SplitterControl.Size, "Size", element, "SplitterControl");

    writeFontToCSS(vcx.BookTabControl.AccessorFont, element, "BookTabControl-AccessorFont");
    writeFontToCSS(vcx.InputControl.LabelFont, element, "InputControl-LabelFont");
    writeFontToCSS(vcx.InputControl.InputFont, element, "InputControl-EditFont");
    writeFontToCSS(vcx.LabelControl.Font, element, "LabelControl-Font");
    writeFontToCSS(vcx.ExpanderControl.HeaderFont, element, "ExpanderControl-HeaderFont");
    writeFontToCSS(vcx.GridControl.Font, element, "GridControl-Font");
    writeFontToCSS(vcx.GridControl.HeaderFont, element, "GridControl-HeaderFont");
    writeFontToCSS(vcx.ListViewControl.Font, element, "ListViewControl-Font");
    writeFontToCSS(vcx.TreeViewControl.Font, element, "TreeViewControl-Font");
    writeFontToCSS(vcx.DashboardControl.TileFont, element, "DashboardControl-TileFont");
    writeFontToCSS(vcx.DashboardControl.TileSmallFont, element, "DashboardControl-TileSmallFont");
    writeFontToCSS(vcx.DashboardControl.FilterInnerFont, element, "DashboardControl-FilterInnerFont");
    writeFontToCSS(vcx.DashboardControl.SimpleTableFont, element, "DashboardControl-SimpleTableFont");
    writeFontToCSS(vcx.TitleControl.Font, element, "TitleControl-Font");

    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrameMin), "ContentFrameMin", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrameMinForChange), "ContentFrameMinForChange", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrame1), "ContentFrame1", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrame1ForChange), "ContentFrame1ForChange", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrame2), "ContentFrame2", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrame2ForChange), "ContentFrame2ForChange", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrame3), "ContentFrame3", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentFrame3ForChange), "ContentFrame3ForChange", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.DataBrowseColorBck), "DataBrowseColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.DataBrowseColorFrg), "DataBrowseColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.DataChangeColorBck), "DataChangeColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.DataChangeColorFrg), "DataChangeColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.AccentBaseColorFrg), "AccentBaseColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.AccentBaseColorBck), "AccentBaseColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.BaseColorFrg1), "BaseColorFrg1", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.BaseColorBck1), "BaseColorBck1", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentChangeColorBck), "ContentChangeColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentDecorateColorFrg), "ContentDecorateColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentNormalColorFrg), "ContentNormalColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentColorBck1), "ContentColorBck1", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ContentChangeDecorateColorFrg), "ContentChangeDecorateColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.WarningColorBck), "WarningColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.WarningColorFrg), "WarningColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ErrorColorBck), "ErrorColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.ErrorColorFrg), "ErrorColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.DataChangeROColorBck), "DataChangeROColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.DataChangeROColorFrg), "DataChangeROColorFrg", element, "ColorMap");

    writePropertyToCSS(vcx.Data.ColorMap.Gray ? "1" : "0", "Gray", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.LumaShift), "LumaShift", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.Alpha), "Alpha", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.AlphaColor), "AlphaColor", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.InactiveAlphaValue), "InactiveAlphaValue", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.InactiveAlphaColor), "InactiveAlphaColor", element, "ColorMap");

    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.HighLightColorBck), "HighLightColorBck", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.HighLightColorFrg), "HighLightColorFrg", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.GridRulerColorBck), "GridRulerColorBck", element, "ColorMap");

    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.HintBackgroundColor1), "HintBackgroundColor1", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.HintBackgroundColor2), "HintBackgroundColor2", element, "ColorMap");
    writePropertyToCSS(vcx.getColor(vcx.Data.ColorMap.HintFontColor), "HintFontColor", element, "ColorMap");
  }
}

export function readPropertyFromCSS<T>(property: string, element: HTMLElement, fullPropertyPath: string, convert: (value: string) => T): T {
  if (element) {
    const fullProperty = createPropertyFullPath(property, fullPropertyPath);
    const cssValue = getComputedStyle(element).getPropertyValue(fullProperty);
    if (cssValue) {
      return convert(cssValue);
    }
    return undefined;
  }
}

function writeFontToCSS(vcx: VCXFontNoColor, element: HTMLElement, fullPropertyPath: string) {
  if (vcx && element) {
    writePropertyToCSS(vcx.FontName, "Name", element, fullPropertyPath);
    writePropertyToCSS(vcx.Data.FontSize, "Size", element, fullPropertyPath);
    writePropertyToCSS(vcx.IsBold ? "bold" : "normal", "Weight", element, fullPropertyPath);
    writePropertyToCSS(vcx.IsItalic ? "italic" : "normal", "Style", element, fullPropertyPath);
    let dec = "normal";
    if (vcx.IsStrikeOut) {
      dec += "line-through";
    }
    if (vcx.IsUnderline) {
      dec += " underline";
    }
    writePropertyToCSS(dec, "Decoration", element, fullPropertyPath);
  }
}

export function writePropertyToCSS(
  value: any,
  property: string,
  element: HTMLElement,
  fullPropertyPath: string,
  convertor: (oldValue: any) => any = undefined
) {
  if (value && element) {
    const fullProperty = createPropertyFullPath(property, fullPropertyPath);
    const old = getComputedStyle(element).getPropertyValue(fullProperty);
    if (old != value) {
      value = convertor ? convertor(old) : value;
      element.style.setProperty(fullProperty, value);
    }
  }
}

function createPropertyFullPath(propertyPath: string, fullPropertyPath: string): string {
  let result = propertyPath;
  if (fullPropertyPath) {
    result = `${fullPropertyPath}-${result}`;
  }
  return `--${result}`;
}
