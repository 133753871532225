import React from "react";
import { getAttributes } from "../../common/common";
import { UFUpdateControl } from "../../common/communication.base";
import { NclFloaterAccessor } from "../../common/components.ncl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import { useServerState } from "../hooks";
import "./FloaterAccessor.scss";

function K2FloaterAccessor(props: WithContextPlacementProps) {
  const [control, data, element] = useServerState<NclFloaterAccessor, UFUpdateControl, HTMLButtonElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFloaterAccessor
  );
  control.addListenerProp({ getOverRect });

  function getOverRect() {
    if (!element.current) return null;

    return element.current.getBoundingClientRect();
  }

  function getClassName() {
    let className = "fa_button fa_font";

    if (control.InEditMode) {
      className += " fa_edit";
    }

    return className;
  }

  return (
    <button
      onClick={() => control.show()}
      data-k2-test-id={control.MetaData.Name}
      className={getClassName()}
      title={data.Title}
      style={StyleHelper(control, { ...props.style, minHeight: `${control.ComputedMinHeight}px` })}
      ref={element}
      {...getAttributes(data)}
    >
      {data.GlyphId && (
        <div className="fa_img">
          <K2Img glyphId={data.GlyphId} width={control.ComputedMinHeight} height={control.ComputedMinHeight} vcx={control.VCX} />
        </div>
      )}
      <K2TruncateText className="fa_label">{data.Title}</K2TruncateText>
    </button>
  );
}

export default K2FloaterAccessor;
