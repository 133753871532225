import React from "react";
import { FileItem } from "../../common/communication.base";
import { VisualContext } from "../../common/visualContext";
import K2Img from "../Image/K2Img";
import css from "./OpenDialog.scss";

interface FileListItem {
  item: FileItem;
  vcx: VisualContext;
  handleRemoveFile(index: number): void;
}

const K2FileListItem = (props: FileListItem) => {
  const fileSizeFormat = (num: number) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };

  const removeFileFromList = () => {
    props.handleRemoveFile(props.item.Id);
  };

  return (
    <div className={css.od_file_list_item}>
      {props.item.FileName.toString()}
      <span className={css.od_file_list_item_size}>{fileSizeFormat(Math.round(props.item.Size / 1024))} kB</span>
      <button onClick={removeFileFromList} className={css.od_file_list_button}>
        <K2Img
          glyphId={"wui*close"}
          strokeColor={props.vcx.getColor(props.vcx.Data.ColorMap.ContentFrame1)}
          vcx={props.vcx}
          height={20}
          width={20}
          stretch={true}
        />
      </button>
    </div>
  );
};

export default K2FileListItem;
