import React from "react";
import { ControlType, NclBaseContainer, NclDockControl, UFNclControlBase } from "../common/components.ncl";
import K2Button from "./Button/K2Button";
import { K2Expander } from "./Expander/K2Expander";
import K2FlowPanel from "./FlowPanel/K2FlowPanel";
import K2Image from "./Image/K2Image";
import { K2LibraryReference } from "./LibraryReference/K2LibraryReference";
import { K2SpaceControl } from "./SpaceControl/K2SpaceControl";
import { K2UndefinedControl } from "./UndefinedControl/K2UndefinedControl";
import { K2Gantt } from "./Gantt/K2Gantt";
import { K2LocatorPanel } from "./LocatorPanel/K2LocatorPanel";
import { K2Ribbon } from "./Ribbon/K2Ribbon";
import K2Input from "./Input/K2Input";
import K2KeyboardInput from "./Input/K2KeyboardInput";
import { K2MFList, K2MultiFormatFText } from "./MultiFormatText/K2MultiFormatText";
import K2WebView from "./WebView/K2WebView";
import K2TabControl from "./TabControl/K2TabControl";
import K2FloaterAccessor from "./FloaterAccessor/K2FloaterAccessor";
import K2GroupBox from "./GroupBox/K2GroupBox";
import K2CheckBox from "./CheckBox/K2CheckBox";
import K2Map from "./Map/K2Map";
import K2StackPanel from "./StackPanel/K2StackPanel";
import K2TabToolBar from "./TabToolBar/K2TabToolBar";
import K2DynamicContent from "./DynamicContent/K2DynamicContent";
import K2Action from "./Action/K2Action";
import K2ActionSeparator from "./ActionSeparator/K2ActionSeparator";
import K2DataGrid from "./DataGrid/K2DataGrid";
import K2DataLabel from "./DataLabel/K2DataLabel";
import K2FormattableInput from "./FormattableInput/K2FormattableInput";
import K2ListView from "./ListView/K2ListView";
import K2FilePreview from "./FilePreview/K2FilePreview";
import K2TreeView from "./TreeView/K2TreeView";
import K2Dashboard from "./Dashboard/K2Dashboard";
import K2Calendar from "./Calendar/K2Calendar";
import K2PreviewPanel from "./PreviewPanel/K2PreviewPanel";
import K2SignInput from "./Signature/K2Signature";
import K2SplitterPanel from "./SplitterPanel/K2SplitterPanel";
import { K2MultiContent } from "./MultiContent/K2MultiContent";
import { K2PageHolder } from "./PageHolder/K2PageHolder";
import K2FlowChart from "./FlowChart/K2FlowChart";
import K2SimpleChart from "./SimpleChart/SimpleChart";
import K2RadioBox from "./RadioBox/K2RadioBox";
import K2ClientWidget from "./ClientWidget/ClientWidget";
import K2Webcam from "./Webcam/K2Webcam";

type ReferenceCallBack = (element: any) => any;

export const GenerateControl = (control?: UFNclControlBase, style?: React.CSSProperties, ref?: ReferenceCallBack): JSX.Element | null => {
  if (!control) return null;
  let result = null;

  switch (control.MetaData.__type) {
    case ControlType.DataLabel:
      result = <K2DataLabel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Input:
      result = <K2Input key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.KeyboardInput:
      result = <K2KeyboardInput key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.FormattableInput:
      result = <K2FormattableInput key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;

    case ControlType.SplitPanel:
      result = <K2SplitterPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.SimpleDataGrid:
      result = <K2DataGrid key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.DataGrid:
      result = <K2DataGrid key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Expander:
      result = (
        <K2Expander key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.Image:
      result = <K2Image key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.FilePreview:
      result = <K2FilePreview key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.DynamicContent:
      result = <K2DynamicContent key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Container:
    case ControlType.Panel:
    case ControlType.PanelBase:
      result = <K2FlowPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.ToolBar:
      result = <K2TabToolBar key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.LibraryReference:
      result = (
        <K2LibraryReference
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;
    case ControlType.PreviewPanel:
      return <K2PreviewPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
    case ControlType.DetailTab:
    case ControlType.Tab:
      result = (
        <K2TabControl
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          style={style}
          isVRTab={false}
        />
      );
      break;
    case ControlType.VRTab: {
      result = (
        <K2TabControl
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          style={style}
          isVRTab={true}
        />
      );
      break;
    }
    case ControlType.MultiContent:
      result = (
        <K2MultiContent key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.ActionTT:
    case ControlType.CommandAction:
    case ControlType.Action:
      result = <K2Action key={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} controlUID={control.MetaData.ControlUID} style={style} />;
      break;
    case ControlType.ButtonFaka:
    case ControlType.Button:
      result = <K2Button key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.CheckBox:
      result = <K2CheckBox key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Map:
      result = <K2Map key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.GroupBox:
      result = <K2GroupBox key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Space:
      result = (
        <K2SpaceControl key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.ActionSeparator:
      result = <K2ActionSeparator key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.StackPanel:
      result = <K2StackPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.FloaterAccessor:
      result = <K2FloaterAccessor key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.LocatorPanel:
      result = (
        <K2LocatorPanel key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />
      );
      break;
    case ControlType.SignInput:
      result = <K2SignInput key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.ListView:
      result = <K2ListView key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Calendar:
      result = <K2Calendar key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Gantt:
      result = <K2Gantt key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />;
      break;
    case ControlType.Ribbon:
      result = <K2Ribbon key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} ref={ref} style={style} />;
      break;
    case ControlType.TreeView:
      result = <K2TreeView key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Dashboard:
      result = <K2Dashboard key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.WebView:
      result = <K2WebView key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.InstantFilterPanel:
      result = <K2MFList key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.FlowChart:
      result = <K2FlowChart key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.WebContentEditor:
      result = (
        <K2UndefinedControl
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;
    case ControlType.SimpleChart:
    case ControlType.SimpleChartDynamic:
      result = <K2SimpleChart key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.MultiFormatText:
      result = (
        <K2MultiFormatFText
          key={control.MetaData.ControlUID}
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          ref={ref}
          style={style}
        />
      );
      break;
    case ControlType.RadioBox:
      result = <K2RadioBox key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.ClientWidget:
      result = <K2ClientWidget key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    case ControlType.Webcam:
      result = <K2Webcam key={control.MetaData.ControlUID} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} style={style} />;
      break;
    default:
      if (control instanceof NclDockControl) {
        result = (
          <K2PageHolder
            key={control.MetaData.ControlUID + "_placeHolder"}
            controlUID={control.MetaData.ControlUID}
            vrUID={control.getRealizerUID()}
            ref={ref}
            style={style}
          />
        );
      } else {
        result = (
          <K2UndefinedControl
            key={control.MetaData.ControlUID}
            controlUID={control.MetaData.ControlUID}
            vrUID={control.getRealizerUID()}
            ref={ref}
            style={style}
          />
        );
      }
  }

  return result;
};

export const GenerateChildrenOfControl = (control: NclBaseContainer): JSX.Element | null => {
  if (control && control.Children && control.Children.size > 0) {
    return <K2FlowPanel key={control.MetaData.ControlUID + "_content"} controlUID={control.MetaData.ControlUID} vrUID={control.getRealizerUID()} />;
  }

  return null;
};
