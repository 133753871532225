import { fromJS } from "immutable";
import { useEffect, useRef, useState } from "react";
import { isDesktopComponent } from "../common/common";
import { CSUpdateControl, FocusData, UpdateControl } from "../common/communication.base";
import { NclControlBase } from "../common/components.ncl";
import { AcquireControl } from "./k2hoc";
import { writeToCSS } from "./VCX/VCXHelper";
import { ViewRealizerManager } from "../viewrealizer";

export const useServerState = <T extends NclControlBase, U extends UpdateControl, V extends HTMLElement>(
  controlUID: string,
  vrUID: string,
  checkType: (ctrl: NclControlBase) => boolean
) => {
  const [control] = useState(() => AcquireControl(controlUID, vrUID, checkType) as T);
  const [data, setData] = useState(control.init({ updateState: updateState, updateVCX: updateVCX, updateFocus: updateFocus }) as U);
  const [vcxVersion, setVCXVersion] = useState(-1);
  const element = useRef<V | null>(null);
  const [focusData, setFocusData] = useState<FocusData>({ isFocused: control.IsFocused });

  if (isDesktopComponent(vrUID)) {
    (window as any)[controlUID] = { updateState };
  }

  useEffect(() => {
    if (control.VCX !== control.Parent?.VCX && element.current) {
      writeToCSS(control.VCX, element.current);
    }

    return () => {
      control.willUnMount(true);
    };
  }, []);

  useEffect(() => {
    control.afterUpdateState();
  }, [data]);

  useEffect(() => {
    if (!focusData.isFocused) return;

    if (focusData.isFocused && !element.current) {
      const vr = ViewRealizerManager.getViewRealizer(vrUID);
      vr.RequestActiveControlUID = vr.PreviousActiveControlUID;

      return;
    }

    element.current.focus({ preventScroll: true });
  }, [focusData]);

  function updateFocus(isFocused: boolean) {
    setFocusData({ isFocused: isFocused }); // je dulezite v setteru vytvaret novy objekt, jinak by pri opakovanem updatu focusu (isFocused === true) nedoslo k refocusu
  }

  function updateState(state: U) {
    if (isDesktopComponent(vrUID)) {
      state = data.with(fromJS(state) as Partial<CSUpdateControl>) as U;
    }

    setData(state);
  }

  function updateVCX(vcxVersion: number) {
    setVCXVersion(vcxVersion);

    if (element.current) {
      writeToCSS(control.VCX, element.current);
    }
  }

  return [control, data, element, vcxVersion, focusData] as const;
};

export const useAnimationEnd = (show: boolean) => {
  const [render, setRender] = useState(false);

  const handleAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  return [render, handleAnimationEnd] as const;
};
