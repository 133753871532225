import React, { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { NodeData } from "../../common/communication.base";

// isConnectable false protože jinak by se na něj dali připojovat Edges (nová nebo rejoin)
export default memo((props: NodeProps<NodeData>) => {
  return (
    <>
      <div className={"node-box"}>
        <p style={{ whiteSpace: "normal" }}>{props.data.label}</p>
        <Handle type="target" position={Position.Top} className={"handleEdge"} isConnectable={false} />
      </div>
    </>
  );
});
