import React, { useEffect, useRef, useState } from "react";
import debounce from "lodash-es/debounce";
import FullCalendar, { DatesSetArg, CalendarApi, EventClickArg } from "@fullcalendar/react";
import { EventApi, Duration, DayCellContentArg, MountArg, EventInput } from "@fullcalendar/core";
import { EventResizeDoneArg } from "@fullcalendar/interaction";
import "@fullcalendar/core/locales/cs.js";
import DatePicker, { registerLocale } from "react-datepicker";
import { cs } from "date-fns/locale";
import ResizeObserver from "resize-observer-polyfill";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { NclCalendar } from "../../common/components.ncl";
import { TLanguageCodeList, UpdateCalendar } from "../../common/communication.base";
import { endDateCorrection, ExtendedProps, GetEvents } from "../common/CalendarFunctions";
import K2Img from "../Image/K2Img";
import { K2CalendarCommon } from "../common/K2Calendar";
import css from "./Calendar.scss";
import { Context } from "../../appcontext";

registerLocale("cs", cs);

const K2Calendar = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclCalendar, UpdateCalendar, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclCalendar
  );
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const calendarApi = useRef<CalendarApi>();
  const datePicker = useRef<HTMLDivElement>();
  const ro = useRef<ResizeObserver>();
  const calendarMounted = useRef(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      window.addEventListener("contextmenu", (e) => e.preventDefault());
    }

    ro.current = new ResizeObserver(() => {
      calendarApi.current.updateSize();
    });
  }, []);

  useEffect(() => {
    if (element.current && !calendarMounted.current) {
      element.current.addEventListener("wheel", disableScroll);
      calendarMounted.current = true;
      ro.current.observe(element.current);
    }
  }, [element.current]);

  const disableScroll = (e: WheelEvent) => {
    if (calendarApi.current.view.type === "dayGrid" || calendarApi.current.view.type === "dayGridMonth") {
      e.preventDefault();
    }
  };

  const getApi = (calendarComponentRef: React.RefObject<FullCalendar>) => {
    calendarApi.current = calendarComponentRef.current.getApi();
  };

  const onChange = (date: Date) => {
    calendarApi.current.changeView("timeGridDay", date);
    setIsDatepickerOpen(false);
  };

  const contextMenuCallback = (args: MountArg<DayCellContentArg>) => {
    control.contextMenu(args.date);
  };

  const showDatepicker = (left: number, top: number) => {
    datePicker.current.style.left = left + "px";
    datePicker.current.style.top = top + "px";
    setIsDatepickerOpen(true);
  };

  const handleDrop = (arg: { el: HTMLElement; event: EventApi; oldEvent: EventApi; delta: Duration; revert: () => void; jsEvent: Event }) => {
    control.DragAnDropAppointment(
      arg.event.extendedProps.SerieUI,
      arg.event.extendedProps.K2Pk,
      arg.oldEvent.start,
      arg.event.start,
      endDateCorrection(arg.event.end, arg.event.allDay, "subtract", arg.event.start),
      arg.event.extendedProps.AppointmentId,
      arg.event.extendedProps.AppointmentItemType
    );
  };

  const handleAppointmentResize = (arg: EventResizeDoneArg) => {
    control.DragAnDropAppointment(
      arg.event.extendedProps.SerieUI,
      arg.event.extendedProps.K2Pk,
      arg.oldEvent.start,
      arg.event.start,
      endDateCorrection(arg.event.end, arg.event.allDay, "subtract", arg.event.start),
      arg.event.extendedProps.AppointmentId,
      arg.event.extendedProps.AppointmentItemType
    );
  };

  const handleDatesRender = (arg: DatesSetArg) => {
    debounceWrap(arg);
  };

  const debounceWrap = debounce((arg: DatesSetArg) => {
    if (!control) return;

    control.ChangeActiveDateRange(arg.view.activeStart, arg.view.activeEnd);
  }, 250);

  const handleSettingButonClick = () => {
    control.executeShowSetting();
  };

  const handleEventClick = (arg: EventClickArg) => {
    if ((arg.jsEvent.target as HTMLElement).className === "fc-button-delete-event") {
      if (arg.event.extendedProps.K2Pk && arg.event.extendedProps.K2Pk != "") {
        control.executeDeleteAppointment(arg.event.extendedProps.SerieUI, arg.event.extendedProps.K2Pk);
      } else {
        control.executeDeleteOnlineAppointment(
          arg.event.extendedProps.SerieUI,
          arg.event.extendedProps.AppointmentId,
          arg.event.extendedProps.AppointmentItemType
        );
      }
    } else {
      if (arg.event.extendedProps.K2Pk && arg.event.extendedProps.K2Pk != "") {
        control.executeShowDocumentByK2PK(arg.event.extendedProps.SerieUI, arg.event.extendedProps.K2Pk);
      } else {
        control.executeEditOnlineAppointment(
          arg.event.extendedProps.SerieUI,
          arg.event.extendedProps.AppointmentId,
          arg.event.extendedProps.AppointmentItemType
        );
      }
    }
  };

  if (!data.InitialView) return null;

  const lEvents: EventInput[] = GetEvents(data.toJS() as UpdateCalendar);

  lEvents.map((event) => {
    (event.extendedProps as ExtendedProps).StateIconJSX = (
      <div className="fc-state-icon">
        <K2Img vcx={control.VCX} glyphId={event.extendedProps.StateGlyphId} width={15} height={15} />
      </div>
    );
  });

  const userInfo = Context.getApplication().UserInfo;

  return (
    <div ref={element} style={StyleHelper(control, props.style)} className={css.calendar}>
      <div ref={datePicker} style={{ position: "fixed", zIndex: 2 }}>
        {isDatepickerOpen && (
          <DatePicker dateFormatCalendar="LLLL" locale="cs" startOpen showYearDropdown onChange={onChange} onCalendarClose={() => setIsDatepickerOpen(false)} />
        )}
      </div>
      <K2CalendarCommon
        handleAppointmentResize={handleAppointmentResize}
        handleDatesRender={handleDatesRender}
        handleDrop={handleDrop}
        handleEventClick={handleEventClick}
        handleSettingButonClick={handleSettingButonClick}
        contextMenuCallback={contextMenuCallback}
        lEvents={lEvents}
        hintBackgroundColor={control.VCX.getColor(control.VCX.Data.ColorMap.DataChangeColorBck)}
        hintBorderColor={control.VCX.getColor(control.VCX.Data.ColorMap.ContentFrame1)}
        getApi={getApi}
        deleteButton={<K2Img glyphId="wui*close" vcx={control.VCX} height={15} width={15} strokeColor="currentColor" />}
        initialStartDate={data.InitialStartDate}
        initialView={data.InitialView}
        showDatepicker={showDatepicker}
        activeStartDate={data.ActiveStartDate}
        activeEndDate={data.ActiveEndDate}
        locale={userInfo.Lng === TLanguageCodeList.lclImplicit ? userInfo.InstallationLanguageNo : userInfo.Lng}
      />
    </div>
  );
};

export default K2Calendar;
