import { List } from "immutable";
import React, { useEffect } from "react";
import { useState } from "react";
import { Context } from "../../appcontext";
import { Size } from "../../common/common";
import { ModalPosition, TBehaviorTypeByDevice } from "../../common/communication.base";
import { NclMenuView } from "../../common/components.ncl";
import { ViewRealizerManager } from "../../viewrealizer";
import { AcquireControl, WithVCXProps } from "../k2hoc";
import { RealizerQueueItem } from "../View/ViewRealizerReact";
import css from "./MenuModal.scss";
import resolveContextMenu from "../../utils/resolveContextMenu";

interface SimpleModalWindowProps extends WithVCXProps {
  realizerUID: string; // realizer for show
  controlUID: string;
  isOverlayBck?: boolean;
  realizersQueue?: List<RealizerQueueItem>;
  updateModalList?: () => void;
}

function K2ModalMenuWindow(props: SimpleModalWindowProps) {
  const [control] = useState(() => AcquireControl(props.controlUID, props.realizerUID, (ctrl) => ctrl instanceof NclMenuView) as NclMenuView);
  const [vr] = useState(() => ViewRealizerManager.getViewRealizer(props.realizerUID));
  const { updateModalList } = props;

  useEffect(() => {
    props.realizersQueue?.map((r) => {
      if (!r.isOpen) {
        updateModalList?.();
      }
    });
  }, [props.realizersQueue, updateModalList]);

  function getPosition(size: Size): ModalPosition {
    let result = { ...Context.getApplication().getMenuPositon() };

    if (!result || (result.y === 0 && Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdNormal)) {
      //Do prvního pohybu myši, aby nebyla na PC výška 98% pro mobily
      result = { x: 1, y: 1 };
    }
    const wSize: Size = document.body.getBoundingClientRect();

    if (result.y + size.height > wSize.height) {
      result.y = Math.max(0, result.y - (result.y + size.height - wSize.height));
    }

    if (result.x + size.width > wSize.width) {
      result.x = Math.max(0, result.x - (result.x + size.width - wSize.width));
    }

    return result;
  }

  function handleOutsideClick(e: React.MouseEvent<HTMLDivElement>) {
    control.closeRequest();
  }

  function handleContextMenu(e: MouseEvent) {
    control.closeRequest();
    e.preventDefault();
  }

  const size: Size = { height: control.ComputedMinHeightWithMargin, width: control.VCX.sizeMap(control.Content?.getMenuWidth() ?? 0) };
  const position = getPosition(size);
  const style: React.CSSProperties = {
    position: "absolute",
    top: position.y,
    left: position.x,
    width: size.width,
  };

  if (position.y === 0) {
    style.height = "98%";
  }

  const isClosed = props.realizersQueue?.some((r) => !r.isOpen);

  if (isClosed) return null;

  return (
    <div
      onClick={handleOutsideClick}
      ref={(ref) => resolveContextMenu(ref, handleContextMenu)}
      style={{ zIndex: vr.getDepth() * 5 }}
      className={css.modal_menu_backdrop}
    >
      <div style={style} className={css.modal_menu}>
        {props.children}
      </div>
    </div>
  );
}

export default K2ModalMenuWindow;
