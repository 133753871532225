import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { K2FullScreenViewer } from "./K2FullScreenViewer";
import css from "./PdfViewer.scss";
import K2Img from "../Image/K2Img";
import { Context, __ } from "../../appcontext";

export interface PdfViewerProps {
  url?: string;
  data?: string;
  fileName?: string;
}

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const K2PdfViewer = (props: PdfViewerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>(null);
  const [pageNumber, _setPageNumber] = useState<number>(1);
  const [containerSize, setContainerSize] = useState<{ height: number; width: number }>({ width: 100, height: 100 });
  const resizeObserver = useRef<ResizeObserver>();

  let data = undefined;
  if (props.data) data = "data:application/pdf;base64," + props.data;

  useEffect(() => {
    const loadPdfWorker = async () => {
      const pdfWorker = await require("pdfjs-dist/build/pdf.worker.min.js");

      // Set the worker source for pdfjs
      pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker.default;
    };
    loadPdfWorker();
  }, []);

  useEffect(() => {
    if (ref.current) {
      resizeObserver.current = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
        if (ref.current) {
          resize(entries, observer);
        }
      });
      resizeObserver.current.observe(ref.current);
    }
  }, [ref.current]);

  const resize = (entries: ResizeObserverEntry[] | [{ target: HTMLDivElement }], observer?: ResizeObserver) => {
    setContainerSize({ width: entries[0].target.clientWidth, height: entries[0].target.clientHeight });
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function setRef(el: HTMLDivElement) {
    if (el) {
      ref.current = el;
    }
  }

  function setPageNumber(pNumber: number): void {
    if (pNumber > 0 && pNumber < numPages + 1) {
      _setPageNumber(pNumber);
    }
  }

  function handleDownloadClick() {
    if (!props.url) {
      console.error("Download link not available");
      return;
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = props.url;
    downloadLink.download = props.fileName || props.url;
    downloadLink.click();
  }

  function isIOS(): boolean {
    return Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS || Context.DeviceInfo.IsMacOS;
  }

  if (!props.url && !props.data) return;
  return (
    <div className={css.pdf}>
      <K2FullScreenViewer fullScreenOption={true} title={props.fileName} asModal={false} zoomAllowed={true} panAllowed={true} setRef={setRef}>
        <div
          className="pdf_wrapper"
          style={{ "--pdf-maxHeight": containerSize.height + "px", "--pdf-maxWidth": containerSize.width + "px" } as React.CSSProperties}
        >
          <Document file={props.url || data} onLoadSuccess={onDocumentLoadSuccess} options={options}>
            <Page pageNumber={pageNumber} scale={isIOS() ? 1 : 3} />
          </Document>
        </div>
      </K2FullScreenViewer>
      {numPages > 1 && (
        <div className="pdf_control">
          <div className="pdf_cp_first" onClick={() => setPageNumber(1)}>
            <K2Img glyphId="wui*first" width={15} height={15} />
          </div>
          <div className="pdf_cp_back" onClick={() => setPageNumber(pageNumber - 1)}>
            <K2Img glyphId="wui*left" width={15} height={15} />
          </div>
          <div className="pdf_cp_page">
            {pageNumber}&nbsp;/&nbsp;{numPages}
          </div>
          <div className="pdf_cp_forward" onClick={() => setPageNumber(pageNumber + 1)}>
            <K2Img glyphId="wui*right" width={15} height={15} />
          </div>
          <div className="pdf_cp_last" onClick={() => setPageNumber(numPages)}>
            <K2Img glyphId="wui*last" width={15} height={15} />
          </div>
        </div>
      )}
      <div onClick={() => handleDownloadClick()} className="pdf_download" title={__("download")}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 32 32">
          <path d="M23 14l-8 8-8-8h5v-12h6v12zM15 22h-15v8h30v-8h-15zM28 26h-4v-2h4v2z" />
        </svg>
      </div>
    </div>
  );
};

export default K2PdfViewer;
