import * as React from "react";
import {
  MFBlockMeasureItem,
  MFBlocksMeasureItem,
  MFMeasure,
  MFSectionMeasureItem,
  TAlignment,
  TBlockWidthMultiplierKind,
  TMFValueCommandState,
  TVerticalAlignment,
  UpdateControl,
  UpdateMultiFormatText,
  VCXFontNoColorMetaData,
  MFValue,
  MultiFormatterValuesPair,
  UpdateInstantFilterPanel,
} from "../../common/communication.base";
import { NclInstantFilterPanel, NclMultiFormatText } from "../../common/components.ncl";
import { VisualContext } from "../../common/visualContext";
import { AcquireControl, K2ComponentState, WithContextPlacementProps, WithVCXProps, StyleHelper } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import { useRef, useState } from "react";
import { Context } from "../../appcontext";
import css from "./MultiFormatText.scss";
import { writeToCSS } from "../VCX/VCXHelper";

interface MultiFormatText extends WithVCXProps {
  texts: Array<MFValue>;
  measure: MFMeasure;
  showError?: boolean;
  errorMessage?: string;
  execute: (commandNumber: number) => void;
  style?: React.CSSProperties;
  strokeColor?: string;
}

export class K2MFText extends React.PureComponent<MultiFormatText> {
  static displayName = `K2MultiFormatText`;

  render() {
    if (this.props.measure && this.props.texts) {
      let content: JSX.Element;
      if (this.props.showError) {
        content = <p>{this.props.errorMessage}</p>;
      } else {
        content = (
          <div style={Object.assign({ flexDirection: "column", width: "100%", height: "100%" }, this.props.style)}>
            {this.props.measure.Sections.map((section, i) => {
              return this.renderSection(section, i);
            })}
          </div>
        );
      }

      return content;
    }

    return null;
  }

  private renderSection(section: MFSectionMeasureItem, index: number): JSX.Element {
    return (
      <div key={`mf_section_${index}`} style={{ width: "100%" }}>
        {this.renderLRBlocks(section.LBlocks)}
        <div style={{ flexDirection: "column", width: "100%" }}>
          {section.Bands.map((block, i) => {
            return this.renderBlocks(block, i, true);
          })}
        </div>
        {this.renderLRBlocks(section.RBlocks)}
      </div>
    );
  }

  private renderLRBlocks(blocks: MFBlocksMeasureItem): JSX.Element | null {
    if (blocks.Height === 0) return null;

    return <>{blocks.Blocks.map((block) => this.renderBlock(block))}</>;
  }

  private renderBlocks(blocks: MFBlocksMeasureItem, index: number, fill = false): JSX.Element | null {
    if (blocks.Height === 0) return null;
    return (
      <div key={`mf_blocks_${index}`} style={{ flex: fill ? "1 0 auto" : "0 0 auto", height: fill ? `${blocks.Height}px` : "100%" }}>
        {blocks.Blocks.map((block) => {
          return this.renderBlock(block);
        })}
      </div>
    );
  }

  private renderBlock(block: MFBlockMeasureItem): JSX.Element {
    let content: JSX.Element;
    if (block.Index < this.props.texts.length) {
      content = this.renderText(block, this.props.texts[block.Index]);
    } else {
      content = <p key={`mf_content_${block.Index}`}>Bind error</p>;
    }
    return content;
  }

  private renderText(block: MFBlockMeasureItem, value: MFValue): JSX.Element {
    let vcx = this.props.vcx;
    if (block.Attrs.Font && block.Attrs.Font.Enabled) {
      const zoom = block.Attrs.Font.Zoom * 100; //change zoom before get style

      if (zoom !== vcx.Zoom) {
        vcx = this.props.vcx.createVCXForZoomFactor(zoom);
      }
    }

    const st = this.getStyle(block, value, vcx);
    const style: React.CSSProperties = st.style;
    let content: JSX.Element | null = null;

    if (block.Attrs.Font && block.Attrs.Font.Enabled) {
      const font = vcx.GridControl.Font.cloneWith({ FontStyle: block.Attrs.Font.FontStyle } as VCXFontNoColorMetaData);
      let decoration = "";

      if (font.IsBold) {
        style.fontWeight = "bold";
      }

      if (font.IsItalic) {
        style.fontStyle = "italic";
      }

      if (font.IsUnderline) {
        decoration = "underline";
      }

      if (font.IsStrikeOut) {
        decoration += " line-through";
      }

      style.textDecoration = decoration;
    }

    if (value.Text?.length > 0) {
      content = <K2TruncateText line={block.Attrs.LineCount}>{value.Text}</K2TruncateText>;
    }

    if (block.Attrs.ShowImage) {
      let widthHeight = vcx.GridControl.GetRowHeight(1) * block.Attrs.LineCount;

      if (st.width && st.width > 0) {
        widthHeight = Math.min(widthHeight, st.width);
      }
      if (widthHeight > this.props.vcx.sizeMap(70)) {
        widthHeight = this.props.vcx.sizeMap(70);
      }

      if (block.Attrs.ImageHorzAlign === TAlignment.taLeftJustify) {
        content = (
          <>
            <K2Img glyphId={value.GlyphId} width={widthHeight} height={widthHeight} {...this.props} strokeColor={this.props.strokeColor} />
            {content}
          </>
        );
      } else if (block.Attrs.ImageHorzAlign === TAlignment.taRightJustify) {
        content = (
          <>
            {content}
            <K2Img glyphId={value.GlyphId} width={widthHeight} height={widthHeight} {...this.props} strokeColor={this.props.strokeColor} />
          </>
        );
      } else {
        if (content) {
          content = (
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                <K2Img glyphId={value.GlyphId} width={widthHeight} height={widthHeight} {...this.props} strokeColor={this.props.strokeColor} />
              </div>
              <div style={{ position: "relative" }}>{content}</div>
            </div>
          );
        } else {
          content = <K2Img glyphId={value.GlyphId} width={widthHeight} height={widthHeight} {...this.props} strokeColor={this.props.strokeColor} />;
        }
      }
    }

    if (value.CommandState) {
      content = (
        <div
          style={{ flex: "1 1 auto", opacity: value.CommandState === TMFValueCommandState.vcsEnabled ? 1 : 0.6, justifyContent: "center" }}
          className="button"
          onClick={() => this.props.execute(block.CommandNumber)}
        >
          {content}
        </div>
      );
    }

    return (
      <div
        ref={(ref) => {
          if (this.props.vcx !== vcx) writeToCSS(vcx, ref);
        }}
        key={`mf_content_${block.Index}`}
        style={style}
        className={css.mft}
      >
        {content}
      </div>
    );
  }

  private getStyle(block: MFBlockMeasureItem, value: MFValue, vcx: VisualContext): { style: React.CSSProperties; width?: number } {
    const result: React.CSSProperties = { height: "100%" };

    if (!block.Attrs.Enabled) return { style: result };

    if (block.Attrs.Border) {
      result.borderStyle = "solid";
      result.borderBottomWidth = result.borderTopWidth = result.borderRightWidth = result.borderLeftWidth = "0px";
      result.borderColor = vcx.getColor(vcx.Data.ColorMap.ContentFrame1);
      if (block.Attrs.Border.Top) {
        result.borderTopWidth = block.Attrs.Border.Width;
      }
      if (block.Attrs.Border.Bottom) {
        result.borderBottomWidth = block.Attrs.Border.Width;
      }
      if (block.Attrs.Border.Left) {
        result.borderLeftWidth = block.Attrs.Border.Width;
      }
      if (block.Attrs.Border.Right) {
        result.borderRightWidth = block.Attrs.Border.Width;
      }
    }

    switch (block.Attrs.TextHorzAlign) {
      case TAlignment.taCenter:
        result.justifyContent = "center";
        break;
      case TAlignment.taLeftJustify:
        result.justifyContent = "flex-start";
        break;
      case TAlignment.taRightJustify:
        result.justifyContent = "flex-end";
        break;
    }

    switch (block.Attrs.TextVertAlign) {
      case TVerticalAlignment.taAlignBottom:
        result.alignItems = "flex-end";
        break;
      case TVerticalAlignment.taAlignTop:
        result.alignItems = "flex-start";
        break;
      case TVerticalAlignment.taVerticalCenter:
        result.alignItems = "center";
        break;
    }
    let width: number | undefined;

    switch (block.WidthMultiplierKind) {
      case TBlockWidthMultiplierKind.bwmkByRatio:
        result.width = "100%";
        break;
      case TBlockWidthMultiplierKind.bwmkDigit:
        if (block.Attrs.Width >= 0) {
          width = vcx.GridControl.Font._0Width * block.Width;
          result.minWidth = `${width}px`;
        }
        break;
      case TBlockWidthMultiplierKind.bwmkEm:
        if (block.Attrs.Width >= 0) {
          width = vcx.GridControl.Font._MWidth * block.Width;
          result.minWidth = `${width}px`;
        }
        break;
      case TBlockWidthMultiplierKind.bwmkPercent:
        if (block.Attrs.Width >= 0) {
          result.width = `${block.Width}%`;
          result.flex = "0 0 auto";
        }
        break;
      case TBlockWidthMultiplierKind.bwmkRowHeight:
        if (block.Attrs.Width >= 0) {
          width = vcx.GridControl.GetRowHeight(block.Width);
          result.minWidth = `${width}px`;
        }
        break;
      case TBlockWidthMultiplierKind.bwmkTextWidth:
        if (value) {
          width = vcx.GridControl.Font.computeTextWidth(value.Text);
          result.minWidth = `${width}px`;
        }
        break;
      default:
        break;
    }

    let color = this.props.vcx.getFormatterColor(this.props.vcx.ColorMap.Data.ContentChangeColorBck, block.Attrs.Font.Color);
    if (color) result.color = color;

    color = this.props.vcx.getFormatterColor(this.props.vcx.ColorMap.Data.BaseColorBck1, block.Attrs.BackColor);
    if (color) result.backgroundColor = color;

    result.display = "flex";
    result.whiteSpace = "nowrap";
    result.overflow = "hidden";
    result.textOverflow = "ellipsis";

    if (block.BlockDeclaredLF > 0 || block.Attrs.LineCount > 1) {
      result.wordBreak = "break-word";
      result.whiteSpace = "break-spaces";
    }

    result.minHeight = `${vcx.GridControl.GetRowHeightWithoutMargin() * block.Attrs.LineCount}px`;
    result.lineHeight = `${vcx.GridControl.GetRowHeight(1)}px`;

    return { style: result, width: width };
  }
}

export class K2MultiFormatFText extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UpdateMultiFormatText>> {
  static displayName = `K2MultiFormatText`;
  private control: NclMultiFormatText;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclMultiFormatText;
    }) as NclMultiFormatText;
    this.state = { data: this.control.init(this) as UpdateMultiFormatText, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateMultiFormatText>) => {
      return { data: state as UpdateMultiFormatText };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  render() {
    if (this.state.data.Measure && this.state.data.Values) {
      return (
        <div style={StyleHelper(this.control, { ...this.props.style, overflow: "hidden" })}>
          <K2MFText
            texts={this.state.data.get("Values" as any).toJS()}
            measure={this.state.data.get("Measure" as any).toJS()}
            {...this.props}
            vcx={this.control.VCX}
            execute={this.control.executeCommandByNumber}
          />
        </div>
      );
    }
    return null;
  }
}

export const K2MFList = (props: WithContextPlacementProps) => {
  const control = useRef(AcquireControl(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclInstantFilterPanel) as NclInstantFilterPanel);
  const [data, setData] = useState(
    control.current.init({
      updateState: updateState,
      updateVCX: updateVCX,
    }) as UpdateInstantFilterPanel
  );
  const [vcxVersion, setVCXVersion] = useState(-1);

  function updateState(state: UpdateInstantFilterPanel) {
    setData(state);
  }

  function updateVCX(vcxVersion: number) {
    setVCXVersion(vcxVersion);
  }

  const handleExecute = (commandNumber: number) => {
    if (control.current) {
      control.current.execute(commandNumber);
    }
  };

  if (!data.InstantFilterMFs) return null;
  const iFilter: any = data.get("InstantFilterMFs" as any).toJS();
  if (!iFilter || !iFilter.List || iFilter.List.length <= 0) return null;
  const gapX = iFilter.GapX / 2;
  const gapY = iFilter.GapY / 2;
  const style: React.CSSProperties = {
    flex: "0 1 auto",
    width: "auto",
    height: "max-content",

    padding: `${iFilter.PaddingX}px ${iFilter.PaddingX}px ${iFilter.PaddingY}px ${iFilter.PaddingX}px`,
    border: ` 1px solid ${control.current.VCX.getColor(control.current.VCX.Data.ColorMap.ContentFrame1)}`,
    margin: `${gapY}px ${gapX}px ${gapY}px ${gapX}px`,
  };
  let strokeColor: string;
  if (iFilter.IsLive) {
    style.backgroundColor = control.current.VCX.getColor(control.current.VCX.ColorMap.Data.DataChangeROColorBck);
    strokeColor = control.current.VCX.getColor(control.current.VCX.ColorMap.Data.ContentDecorateColorFrg);
  }
  if (Context.DeviceInfo.IsTouchDevice) {
    style.overflowY = "auto";
  }
  const st: React.CSSProperties = {
    minHeight: "min-content",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    padding: `${gapY}px ${gapX}px ${gapY}px ${gapX}px`,
    backgroundColor: control.current.VCX.getColor(control.current.VCX.ColorMap.Data.DataBrowseColorBck),
  };
  return (
    <div style={StyleHelper(control.current, st)} className={"mf_list"}>
      {iFilter.List.map((value: MultiFormatterValuesPair, index: number) => {
        const values: any = value.Values;
        return (
          <div style={style} key={`mf_${index}`}>
            <K2MFText measure={value.Measure} texts={values as Array<MFValue>} vcx={control.current.VCX} execute={handleExecute} strokeColor={strokeColor} />
          </div>
        );
      })}
    </div>
  );
};
