import React from "react";
import { ClientWidgetUpdate, HorizontalAlignment, TClientWidgetType, VerticalAlignment } from "../../common/communication.base";
import { NclClientWidget } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import "./ClientWidget.scss";
import K2ClockWidget from "./ClockWidget";
import { K2ExternalWidget } from "./ExternalWidget";

export interface BaseWidgetProps {
  AdditionalField: string;
}

export default function K2ClientWidget(props: WithContextPlacementProps) {
  const [control, data, element] = useServerState<NclClientWidget, ClientWidgetUpdate, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclClientWidget
  );
  const classes = ["cw"];

  function getWidget(): React.ReactNode {
    switch (control.Ncl.FrgtData.ClientWidgetType) {
      case TClientWidgetType.cwtClock:
        return <K2ClockWidget AdditionalField={control.Ncl.FrgtData.AdditionalField} />;
      case TClientWidgetType.cwtCustom:
        return <K2ExternalWidget data={data} url={control.Ncl.FrgtData.CustomWidgetSource} AdditionalField={control.Ncl.FrgtData.AdditionalField} />;
    }
  }

  switch (control.Ncl.FrgtData.VerticalAlignment) {
    case VerticalAlignment.fvaTop:
      classes.push("vertical_top");
      break;
    case VerticalAlignment.fvaCenter:
      classes.push("vertical_center");
      break;
    case VerticalAlignment.fvaBottom:
      classes.push("vertical_bottom");
      break;
  }
  switch (control.Ncl.FrgtData.HorizontalAlignment) {
    case HorizontalAlignment.fhaLeft:
      classes.push("horizontal_left");
      break;
    case HorizontalAlignment.fhaCenter:
      classes.push("horizontal_center");
      break;
    case HorizontalAlignment.fhaRight:
      classes.push("horizontal_right");
      break;
  }

  return (
    <div style={StyleHelper(control, props.style)} ref={element} className={classes.join(" ")}>
      {getWidget()}
    </div>
  );
}
