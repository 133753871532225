/** POLYFILL FOR OnContextMenu
 *
 * Na IOS bohužel contextmenu listener nefunguje, proto je potřeba používat tento WorkAroud
 * V případě podpory contextmenu se použije nativní chování.
 *
 * */

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function resolveContextMenu(element: HTMLElement | Window, callback: (e: TouchEvent | MouseEvent | Event) => any, capture = false) {
  const TOUCH_INTERVAL = 500; // Miliseconds
  let _timeout: NodeJS.Timeout;

  if (element) {
    if (isSafari) {
      element.addEventListener("contextmenu", (e) => callback(e), { capture: capture });
      element.addEventListener("touchstart", (e) => handleTouchStart(e), { capture: capture });
      element.addEventListener("touchend", (e) => handleTouchEnd(e), { capture: capture });
    } else {
      element.addEventListener("contextmenu", (e) => callback(e), { capture: capture });
    }
  }

  function handleTouchStart(e: TouchEvent | Event) {
    clearTimeout(_timeout);
    _timeout = setTimeout(() => {
      callback(e);
    }, TOUCH_INTERVAL);
  }
  function handleTouchEnd(e: TouchEvent | Event) {
    clearTimeout(_timeout);
  }
}

export default resolveContextMenu;
