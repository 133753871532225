import * as React from "react";
import { Helper } from "../common/common";

export default class ErrorBoundary extends React.Component<{ children?: React.ReactNode }> {
  constructor(props: { children?: React.ReactNode }) {
    super(props);
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    Helper.sendErrorMessage(error.message, error.stack, info.componentStack);
  }

  render() {
    return this.props.children;
  }
}
