import React from "react";
import { CSUFNclListViewGroupMetadata, CSUFNclListViewItemMetadata, UpdateListView } from "../../common/communication.base";
import { NclListView } from "../../common/components.ncl";
import { K2Header } from "../Expander/K2Expander";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import css from "./ListView.scss";
import resolveContextMenu from "../../utils/resolveContextMenu";

export enum MoveDirection {
  mdLeft,
  mdTop,
  mdRight,
  mdBottom,
}

const K2ListView = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclListView, UpdateListView, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclListView
  );

  const handleDblClick = (itemPosition: number) => {
    control.executeSetPosition(itemPosition);
    control.executeShortcut([itemPosition]);
  };

  const handleContextMenu = (itemPosition: number) => {
    control.executeSetPosition(itemPosition, false);
    control.contextMenu(itemPosition);
  };

  const handleClick = (itemPosition: number) => {
    control.executeSetPosition(itemPosition);
  };

  const internalMoveLeftOrRight = (actualPosition: number, direction: MoveDirection) => {
    const groups: Array<CSUFNclListViewGroupMetadata> = data.get("ListViewGroups" as any).toJS();

    for (let group = 0; group < groups.length; group++) {
      const item: CSUFNclListViewGroupMetadata = groups[group];
      for (let groupItem = 0; groupItem < item.ListViewItems.length; groupItem++) {
        const elementI = item.ListViewItems[groupItem];
        if (actualPosition == elementI.Position) {
          if (direction == MoveDirection.mdLeft) {
            if (groupItem == 0) {
              return elementI.Position;
            } else {
              return item.ListViewItems[groupItem - 1].Position;
            }
          }
          if (direction == MoveDirection.mdRight) {
            if (item.ListViewItems.length - 1 > groupItem) {
              return item.ListViewItems[groupItem + 1].Position;
            } else {
              return elementI.Position;
            }
          }
        }
      }
    }
  };

  const internalMoveTopOrBottom = (actualPosition: number, direction: MoveDirection) => {
    let isUpperGroup = false;
    let isBottomGroup = false;
    const groups: Array<CSUFNclListViewGroupMetadata> = data.get("ListViewGroups" as any).toJS();

    for (let group = 0; group < groups.length; group++) {
      const item: CSUFNclListViewGroupMetadata = groups[group];
      isUpperGroup = group > 0;
      isBottomGroup = group < groups.length - 1;

      for (let groupItem = 0; groupItem < item.ListViewItems.length; groupItem++) {
        const elementI = item.ListViewItems[groupItem];

        if (actualPosition == elementI.Position) {
          if (direction == MoveDirection.mdTop) {
            if (groupItem == 0) {
              if (isUpperGroup) {
                return groups[group - 1].ListViewItems[0].Position;
              } else {
                return elementI.Position;
              }
            } else {
              return groups[group].ListViewItems[0].Position;
            }
          }

          if (direction == MoveDirection.mdBottom) {
            if (groupItem == groups[group].ListViewItems.length - 1) {
              if (isBottomGroup) {
                return groups[group + 1].ListViewItems[0].Position;
              } else {
                return elementI.Position;
              }
            } else {
              if (!isBottomGroup) return elementI.Position;

              return groups[group + 1].ListViewItems[0].Position;
            }
          }
        }
      }
    }
  };

  const getNextPositionForDirection = (FromPosition: number, direction: MoveDirection) => {
    switch (direction) {
      case MoveDirection.mdBottom:
        return internalMoveTopOrBottom(FromPosition, MoveDirection.mdBottom);
      case MoveDirection.mdLeft:
        return internalMoveLeftOrRight(FromPosition, MoveDirection.mdLeft);
      case MoveDirection.mdRight:
        return internalMoveLeftOrRight(FromPosition, MoveDirection.mdRight);
      case MoveDirection.mdTop:
        return internalMoveTopOrBottom(FromPosition, MoveDirection.mdTop);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    control.setActiveControlRequested();
    e.stopPropagation();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowUp") {
      const NextPosition: number = getNextPositionForDirection(data.Position, MoveDirection.mdTop);
      control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowDown") {
      const NextPosition: number = getNextPositionForDirection(data.Position, MoveDirection.mdBottom);
      control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowLeft") {
      const NextPosition: number = getNextPositionForDirection(data.Position, MoveDirection.mdLeft);
      control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }

    if (e.key === "ArrowRight") {
      const NextPosition: number = getNextPositionForDirection(data.Position, MoveDirection.mdRight);
      control.executeSetPosition(NextPosition);
      e.stopPropagation();
      return false;
    }
    if (e.key === "Enter") {
      control.executeShortcut([data.Position]);
      e.stopPropagation();
      return false;
    }
  };

  let itemsInGroup: Array<JSX.Element> = null;
  let groups: Array<JSX.Element> = null;
  const listViewGroups: Array<CSUFNclListViewGroupMetadata> = data.get("ListViewGroups" as any).toJS();

  groups = listViewGroups.map((item: CSUFNclListViewGroupMetadata, groupIndex) => {
    itemsInGroup = item.ListViewItems.map((elementI: CSUFNclListViewItemMetadata, index) => {
      const itemPosition: number = elementI.Position;
      const isItemSelected: boolean = itemPosition == data.Position;
      let className = css.lw_button;

      if (isItemSelected) {
        className += ` ${css.lw_selected}`;
      }

      return (
        <li
          onClick={() => handleClick(itemPosition)}
          onDoubleClick={() => handleDblClick(itemPosition)}
          ref={(ref) => {
            resolveContextMenu(ref, () => handleContextMenu(itemPosition));
          }}
          key={"listElementGroupCaption_" + index}
          className={className}
        >
          <K2Img glyphId={elementI.GlyphId} height={32} width={32} vcx={control.VCX} />
          <p className={css.lw_caption}>{elementI.Caption}</p>
        </li>
      );
    });

    return (
      <div className={css.lw_group} key={groupIndex}>
        <p className={css.lw_title}>{item.Caption}</p>
        <ul className={css.lw_buttons}>{itemsInGroup}</ul>
      </div>
    );
  });

  return (
    <div
      ref={element}
      id={control.Ncl.Name}
      tabIndex={1}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      style={StyleHelper(control, props.style)}
      className={css.lw}
    >
      {control.isShowHeader() && <K2Header controlUID={control.Header.Ncl.ControlUID} vrUID={control.Header.getRealizerUID()} />}
      {groups}
    </div>
  );
};

export default K2ListView;
