import React from "react";
import { Align, UpdateControl, CSUFNclControlMetadata } from "../common/communication.base";
import { NclContainerBase, NclControlBase, NclHeaderedControl, NclPage } from "../common/components.ncl";
import { VisualContext } from "../common/visualContext";
import { ViewRealizerManager, ViewRealizer } from "../viewrealizer";
import { Context } from "../appcontext";
import { isDesktopComponent } from "../common/common";

export interface WithContextProps {
  children?: React.ReactNode;
}

export interface WithVCXProps extends WithContextProps {
  vcx: VisualContext;
}

export interface K2ComponentState<S extends UpdateControl> {
  data: S;
  vcxVersion: number;
}

export interface WithContextPlacementProps extends WithContextProps {
  vrUID: string;
  controlUID: string;
  style?: React.CSSProperties;
  className?: string;
}

function getVerticalAlign(align: Align): "flex-start" | "flex-end" | "stretch" | "auto" {
  if (align != null) {
    switch (align) {
      case Align.Bottom:
        return "flex-end";
      case Align.Top:
        return "flex-start";
      case Align.Client:
        return "stretch";
      default:
        return "auto";
    }
  }

  return "auto";
}

export function getTextDecoration(isStrikeOut: boolean, isUnderline: boolean): "line-through" | "underline" | "unset" | "line-through underline" {
  if (isStrikeOut && isUnderline) {
    return "line-through underline";
  }

  if (isStrikeOut) {
    return "line-through";
  }

  if (isUnderline) {
    return "underline";
  }

  return "unset";
}

type CheckType = (ctrl: any) => boolean;
/**
 * Methods try fond control in their VR elements, when not found or found bad type of control than throw error
 * @param controlUID Identification of control.
 * @param viewRealizerUID  Identification of VR.
 * @param checkType Method for check type.
 * @param notFoundError When set to true method throw error when element not found.
 */
export const AcquireControl = (controlUID: string, viewRealizerUID: string, checkType: CheckType, notFoundError = true): NclControlBase => {
  if (isDesktopComponent(viewRealizerUID)) {
    const control = NclContainerBase.createControl(
      { __type: controlUID, ControlUID: controlUID } as any,
      null,
      { realizerUID: viewRealizerUID, getRealizerUID: () => "desktop", VCX: VisualContext.Default, removeFromChangeList: () => {} } as any,
      null
    );

    return control;
  }

  let vr: ViewRealizer = null;

  if (viewRealizerUID && viewRealizerUID !== "") {
    vr = ViewRealizerManager.getViewRealizer(viewRealizerUID);
  }

  if (!vr) {
    vr = Context.getApplication().getActiveRealizer();
  }

  if (!vr) return null;

  const ctrl = vr.getControlByUID(controlUID);

  if (!ctrl) {
    if (!notFoundError) return null;
    throw Error(`Control ${controlUID} not found.`);
  }

  if (!checkType(ctrl)) {
    throw Error(`Control ${controlUID} has invalid type: ${ctrl.MetaData.__type}.`);
  }

  return ctrl;
};

export const StyleHelper = (ctrl: NclControlBase, style?: React.CSSProperties): React.CSSProperties => {
  const result: React.CSSProperties = style ? Object.assign({}, style) : {};
  if (!ctrl || isDesktopComponent(ctrl.getRealizerUID())) {
    return result;
  }

  const metadata: CSUFNclControlMetadata = ctrl.MetaData as CSUFNclControlMetadata;
  if (metadata) {
    if (metadata.Bounds) {
      if (!result.width && !(ctrl instanceof NclPage))
        result.width = metadata.Bounds.Align === Align.Left || metadata.Bounds.Align === Align.Right ? "auto" : "100%";

      if (!result.alignSelf) {
        result.alignSelf = getVerticalAlign(metadata.Bounds.Align);
      }
    }
  }

  if (!result.minHeight && ctrl.ComputedMinHeightWithMargin >= 1) {
    if (ctrl.Size >= 0 && !(ctrl instanceof NclContainerBase) && !(ctrl instanceof NclHeaderedControl)) {
      result.minHeight = ctrl.ComputedMinHeightWithMargin + "px";
    }
  }

  if (!result.minWidth && ctrl instanceof NclContainerBase) {
    result.minWidth = ctrl.VCX.LabelControl.Font._MWidth * ctrl.MetaData.Bounds.BandsCount;
  }

  if (!result.whiteSpace) result.whiteSpace = "nowrap";

  if (ctrl.State.Visible === false) {
    result.display = "none";
  }

  return result;
};
