declare global {
  interface Window {
    Foxentry: any;
  }
}

declare class FoxentryBase {
  constructor(apiKey: string);
}

const initFoxentry = (apiKey: string) => {
  if (!apiKey) return;

  (function () {
    const e = document.querySelector("script"),
      s = document.createElement("script");
    s.setAttribute("type", "text/javascript");
    s.setAttribute("async", "true");
    s.setAttribute("src", "https://cdn.foxentry.cz/lib");
    e.parentNode.appendChild(s);
    s.onload = function () {
      window.Foxentry = new FoxentryBase(apiKey);
    };
  })();
};

export default initFoxentry;
