import { LogLevel, Log, Helper, queryVariable, removeUrlParam, themeSwitchTo } from "./common/common";
import { Context } from "./appcontext";
import { K2App } from "./components/app";
import "../wwwroot/css/default.css";
import "../wwwroot/css/datepicker.css";
import "../wwwroot/css/default_mouse_device.css";
import "./components/VCX/variables.css";
import versionJson from "../wwwroot/info.json";

if (versionJson.version === "development") {
  console.warn("Development version - For development purposes only!");
}

window.onload = (ev: Event) => {
  Log.init(LogLevel.Error);
  themeInit();
  Context.registerApplication(new K2App());
  Context.getApplication().run();
};

window.onunload = (ev: Event) => {
  Context.getApplication().close(false, true);
};

if (process.env.NODE_ENV !== "development") {
  // Zakázat tlačítko ZPĚT v prohlížeči
  history.pushState(null, null, document.URL);
  window.addEventListener("popstate", function () {
    history.pushState(null, null, document.URL);
    Context.getApplication().getActiveRealizer().processHotKey("ESC");
  });

  // Zakázet pravé tlačítko myši pro potlačení výchozího contexMenu nad internetovým prohlížečem.
  window.addEventListener("contextmenu", function () {
    event.preventDefault();
  });
}

function addCssFile(file: string): void {
  // načte css soubor do hlavičky webu
  if (!document.getElementById(file)) {
    const head = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");
    link.id = file;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "./css/" + file + ".css";
    link.media = "all";
    head.appendChild(link);
  }
}

function themeInit() {
  const css = queryVariable("custom-style", undefined);
  if (css) {
    themeSwitchTo(css);

    const sourceUrl = window.location.href;
    const newUrl = removeUrlParam("custom-style", sourceUrl);
    history.replaceState(null, "", newUrl);
    return;
  }

  if (localStorage.getItem("k2theme")) {
    themeSwitchTo(localStorage.getItem("k2theme"));
  }
}

/* KONEC - Pro dotyková zařízení */

// var DESIGN_LAYOUT = false; // vykresli pouze zakladni layout, tlacitka a taby
