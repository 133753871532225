import React, { useEffect, useRef, useState } from "react";
import { Context } from "../../appcontext";
import { Orientation } from "../../common/communication.base";
import { NclBaseTabControl } from "../../common/components.ncl";
import K2Img from "../Image/K2Img";
import css from "./Scroll.scss";
import ResizeObserver from "resize-observer-polyfill";

interface ScrollProps {
  orientation: number;
  control: any;
  children?: React.ReactNode;
}

const K2Scroll = (props: ScrollProps) => {
  const content = useRef<HTMLDivElement>();
  const resizeObserver = useRef<ResizeObserver>();
  const prevPages = useRef(0);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      if (content.current) {
        toggleScrollButtons(entries, observer);
      }
    });

    resizeObserver.current.observe(content.current);
  }, []);

  useEffect(() => {
    if (prevPages.current !== props.control.Pages.size) {
      prevPages.current = props.control.Pages.size;
      setShowButtons(false);
    }
  }, [props.control.Pages.size]);

  const toggleScrollButtons = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (props.control instanceof NclBaseTabControl && window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      // v mobilnim zobrazeni funguje TabControl trochu jinak, takze se tady scroll nepouziva
      content.current.style.overflow = "visible";
      setShowButtons(false);
    } else {
      content.current.style.overflow = "auto";

      if (entries[0].target.clientWidth < entries[0].target.scrollWidth || entries[0].target.clientHeight < entries[0].target.scrollHeight) {
        entries[0].target.scrollLeft = entries[0].target.scrollWidth;
        setShowButtons(true);
      } else {
        setShowButtons(false);
      }
    }
  };

  let firstButton: JSX.Element;
  let secondButton: JSX.Element;
  const clsName = `${css.scroll_button}${props.orientation === Orientation.foVertical ? ` ${css.scroll_button_vertical}` : ""}`;

  if (props.orientation === Orientation.foHorizontal) {
    firstButton = (
      <button
        className={clsName}
        style={{ display: "block", borderRight: "1px solid white" }}
        onClick={() => (content.current.scrollLeft -= props.control.VCX.sizeMap(100))}
      >
        <div style={{ justifyContent: "center" }}>
          <K2Img
            strokeColor={"white"}
            glyphId="wui*triangleleft"
            vcx={props.control.VCX}
            height={props.control.VCX.sizeMap(20)}
            width={props.control.VCX.sizeMap(20)}
          />
        </div>
      </button>
    );
    secondButton = (
      <button
        className={clsName}
        style={{ display: "block", borderLeft: "1px solid white" }}
        onClick={() => (content.current.scrollLeft += props.control.VCX.sizeMap(100))}
      >
        <div style={{ justifyContent: "center" }}>
          <K2Img
            strokeColor={"white"}
            glyphId="wui*triangleright"
            vcx={props.control.VCX}
            height={props.control.VCX.sizeMap(20)}
            width={props.control.VCX.sizeMap(20)}
          />
        </div>
      </button>
    );
  } else {
    firstButton = (
      <button
        className={clsName}
        style={{ display: "block", borderBottom: "1px solid white" }}
        onClick={() => (content.current.scrollTop -= props.control.VCX.sizeMap(100))}
      >
        <div style={{ justifyContent: "center" }}>
          <K2Img
            strokeColor={"white"}
            glyphId="wui*triangleup"
            vcx={props.control.VCX}
            height={props.control.VCX.sizeMap(20)}
            width={props.control.VCX.sizeMap(20)}
          />
        </div>
      </button>
    );
    secondButton = (
      <button
        className={clsName}
        style={{ display: "block", borderTop: "1px solid white" }}
        onClick={() => (content.current.scrollTop += props.control.VCX.sizeMap(100))}
      >
        <div style={{ justifyContent: "center" }}>
          <K2Img
            strokeColor={"white"}
            glyphId="wui*triangledown"
            vcx={props.control.VCX}
            height={props.control.VCX.sizeMap(20)}
            width={props.control.VCX.sizeMap(20)}
          />
        </div>
      </button>
    );
  }

  return (
    <div style={{ flex: "1 1 auto", flexDirection: props.orientation === Orientation.foHorizontal ? "row" : "column" }}>
      {showButtons && firstButton}
      <div className={`${css.scroll_content}${props.orientation === Orientation.foVertical ? ` ${css.scroll_content_vertical}` : ""}`} ref={content}>
        {props.children}
      </div>
      {showButtons && secondButton}
    </div>
  );
};

export default K2Scroll;
