import React from "react";
import { ViewRealizer, ViewRealizerControlListener, ViewRealizerManager } from "../../viewrealizer";
import { UFNclControlBase, NclViewBase, NclEmptyViewDialog } from "../../common/components.ncl";
import { K2View } from "./K2View";
import { K2EmptyView } from "../EmptyView/K2EmptyView";
import K2Modal from "../Modal/K2Modal";
import { List } from "immutable";

interface ViewRealizerReactProps {
  VRUID: string;
  overlayBck: boolean;
  realizersQueue?: List<RealizerQueueItem>;
  skipNdx?: number;
  updateModalList?: () => void;
}

export interface RealizerQueueItem {
  realizerUID: string;
  controlUID?: string;
  isOpen: boolean;
  key: number | undefined;
}

interface ViewRealizerState {
  rootUID: string;
  realizersQueue: List<RealizerQueueItem>;
  isActive: boolean;
}

/**
 * Otevirani a zavirani modalnich oken ridi metody showModal a closeModal.
 * Pri otevreni modalniho okna se vola metoda showModal, ktera prida do seznamu aktualne otevirane okno, a nasledne se (prostrednictvim K2Modal) vykresli komponenta:
 * - ViewRealizerReact v pripade, ze se ma otevrit dalsi modalni okno. V tomto pripade je nutne pomoci property 'skip' rict, ze se ma preskocit dalsi volani komponenty
 *   ViewRealizerReact, aby nedoslo k nekonecne smycce. 'controlUID' v metode showModal je undefined.
 * - K2View v pripade, ze se vykresli control (napr. Floater).
 * Po zavreni modalniho okna se vola metoda closeView, ktera nastavi priznak pro zavreni modalniho okna, a provede se animace zavreni.
 * Po dokonceni animace se zavola metoda updateModalList, ktera odstrani modalni okno se seznamu a znovu se provede rerender, aby bylo okno vymazano z DOMu.
 * Do budoucna by se mohla logika prepsat za pouziti React Portalu, aby se nemusely volat metody pro rizeni oken z vnejsku.
 */
export class ViewRealizerReact extends React.Component<ViewRealizerReactProps, ViewRealizerState> implements ViewRealizerControlListener {
  private vr: ViewRealizer | null = null;
  private realizerUID: string | undefined;
  private controlUID: string | undefined;
  private key: number | undefined;
  private resolve: ((value: void | PromiseLike<void>) => void) | undefined;

  constructor(props: ViewRealizerReactProps) {
    super(props);
    this.vr = ViewRealizerManager.getViewRealizer(this.props.VRUID);
    this.state = {
      rootUID: this.vr.getRoot().MetaData.ControlUID,
      realizersQueue: List<RealizerQueueItem>(this.props.realizersQueue),
      isActive: false,
    };
  }

  getViewRealizer(): ViewRealizer | null {
    return this.vr;
  }

  setAsInActive(value: boolean): void {
    this.setState({ isActive: value });
  }

  showModal(realizerUID: string, controlUID?: string): Promise<void> {
    const reusedUID = this.state.realizersQueue.find(
      (realizerQueueItem) => realizerQueueItem.realizerUID === realizerUID && realizerQueueItem.controlUID === controlUID
    ); // v pripade, ze po zavreni modalniho okna se otevre dalsi okno se stejnym realizer UID, je nutne vykreslit komponentu znovu pomoci jineho key

    if (reusedUID) {
      this.key = Date.now();
    }

    this.setState((state) => {
      return { realizersQueue: state.realizersQueue.push({ realizerUID: realizerUID, controlUID: controlUID, isOpen: true, key: this.key }) };
    });

    return Promise.resolve();
  }

  closeModal(realizerUID: string, controlUID?: string): Promise<void> {
    return new Promise((resolve) => {
      this.realizerUID = realizerUID;
      this.controlUID = controlUID;
      this.resolve = resolve;

      this.setState((state) => {
        const realizersQueue = state.realizersQueue.map((realizerQueueItem) => {
          const newRealizerQueueItem = { ...realizerQueueItem };

          if (realizerQueueItem.realizerUID === realizerUID && realizerQueueItem.controlUID === controlUID) {
            newRealizerQueueItem.isOpen = false;
          }

          return newRealizerQueueItem;
        });

        return { realizersQueue: realizersQueue };
      });
    });
  }

  isSupportModal(): boolean {
    if (this.vr) {
      return this.vr.getPriorRealizer() === null || this.vr.getPriorRealizer().isModal();
    }

    return false;
  }

  reRealize(): Promise<void> {
    return new Promise((resolve) => {
      this.setState(
        () => {
          //pokud jsem dal jen novy root tak se mi K2view neprekreslilo, kdyz dam null a novy tak se prekresli. necham to tak, myslim ze to bude jinak az zakomponuju control mimo props.
          return { rootUID: "", realizersQueue: List<RealizerQueueItem>() };
        },
        () => {
          this.setState(
            () => {
              return { rootUID: this.vr?.getRoot().MetaData.ControlUID ?? "" };
            },
            () => {
              resolve();
            }
          );
        }
      );
    });
  }

  updateModalList = () => {
    this.state.realizersQueue.map((realizerQueueItem) => {
      const newRealizersQueue = this.state.realizersQueue.filter((item) => !(item.realizerUID === this.realizerUID && item.controlUID === this.controlUID));

      if (realizerQueueItem.realizerUID === this.realizerUID) {
        this.setState({ realizersQueue: List<RealizerQueueItem>(newRealizersQueue) });
        this.resolve?.();
      }
    });
  };

  render() {
    if (this.vr && this.state.rootUID != "") {
      const ctrl = this.vr.getControlByUID(this.state.rootUID) as UFNclControlBase;

      if (!ctrl) return null;

      let modal: JSX.Element | null = null;

      if (this.isSupportModal()) {
        modal = (
          <K2Modal
            realizerUID={this.vr.getRealizerUID()}
            realizersQueue={this.state.realizersQueue}
            isActive={this.state.isActive}
            updateModalList={this.updateModalList}
            skipNdx={this.props.skipNdx}
          />
        );
      }
      if (ctrl instanceof NclEmptyViewDialog) {
        return (
          <K2EmptyView
            controlUID={ctrl.MetaData.ControlUID}
            vrUID={this.vr.getRealizerUID()}
            updateModalList={this.props.updateModalList}
            realizersQueue={this.props.realizersQueue}
          ></K2EmptyView>
        );
      } else if (ctrl instanceof NclViewBase) {
        return (
          <K2View
            controlUID={ctrl.MetaData.ControlUID}
            vrUID={this.vr.getRealizerUID()}
            overlayBck={this.props.overlayBck}
            updateModalList={this.props.updateModalList}
            realizersQueue={this.props.realizersQueue}
          >
            {modal}
          </K2View>
        );
      } else {
        throw new Error("Invalid control type: " + ctrl.MetaData.__type);
      }
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (this.vr) {
      this.vr.afterMount(this);
    }
  }

  componentWillUnmount() {
    if (this.vr) {
      this.vr.willUnMount();
    }

    this.vr = null;
  }
}
