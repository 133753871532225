import React, { useContext } from "react";
import { NclBaseTabControl, NclPage, NclVRTabControl } from "../../common/components.ncl";
import { CSNclTabControlMetadata, IconPosition, UpdateTabControl } from "../../common/communication.base";
import { VCXContext } from "../../context";
import K2Img from "../Image/K2Img";
import css from "./ResponsiveTabControl.scss";
import { StyleHelper } from "../k2hoc";
import K2TruncateText from "../Text/K2TruncateText";
import "./Tab.scss";
import resolveContextMenu from "../../utils/resolveContextMenu";

interface ResponsiveTabControlProps {
  control: NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>;
  currentPage: string;
  showMobileMenu: boolean;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleAnimationEnd: () => void;
}

function K2ResponsiveTabControl(props: ResponsiveTabControlProps) {
  const vcxContext = useContext(VCXContext);
  const pages = visiblePages();

  function visiblePages() {
    const pages = props.control.visiblePages();

    if (props.control instanceof NclVRTabControl) {
      return pages.filter((page) => page.Ncl.IsDynamic);
    }

    return pages;
  }

  function getGlyphId(page: NclPage) {
    if (page.State.GlyphId) return page.State.GlyphId;
    return page.MetaData.FrgtData.Icon;
  }

  function handleContextMenu(e: MouseEvent | Event | TouchEvent, page: NclPage) {
    e.preventDefault();
    page.showContextMenu();
  }

  return (
    <div
      onClick={() => props.setShowMobileMenu(false)}
      className={`${css.tc_backdrop} ${props.showMobileMenu ? css.tc_responsive_backdrop_open : css.tc_responsive_backdrop_close}`}
    >
      <div className={css.tc_close}>
        <K2Img glyphId="wui*close" width={30} height={30} vcx={vcxContext.vcx} />
      </div>
      <div className={css.tc_responsive_content}>
        <div className={css.tc_responsive_buttons}>
          {pages.map((page, i) => (
            <div
              style={{
                animationDelay: props.showMobileMenu ? `${i / 20}s` : `${(pages.size - i) / 20}s`,
                flex: "0 0 auto",
              }}
              className={props.showMobileMenu ? css.tc_responsive_button_up : css.tc_responsive_button_down}
              onAnimationEnd={pages.size - 1 === i ? props.handleAnimationEnd : null}
              key={page.MetaData.ControlUID}
            >
              <div
                ref={(ref) => {
                  resolveContextMenu(ref, (e) => handleContextMenu(e, page));
                }}
                style={StyleHelper(page)}
                className={`${css.tc_responsive_button} ${props.currentPage === page.Ncl.PageUID ? css.tc_current_page : ""}`}
                onClick={() => props.control.changeCurrentPage(page.Ncl.PageUID)}
                data-k2-test-id={"page_" + page.Content.MetaData.Name}
                title={page.State.Title}
              >
                <div className="tc_button_content">
                  {getGlyphId(page) && props.control.Ncl.FrgtData.ShowIcon !== false && (
                    <K2Img glyphId={getGlyphId(page)} vcx={page.VCX} height={page.VCX.sizeMap(20)} width={page.VCX.sizeMap(20)} />
                  )}
                  {props.control.Ncl.FrgtData.TabIconPosition !== IconPosition.ipCenter && <K2TruncateText>{page.State.Title}</K2TruncateText>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default K2ResponsiveTabControl;
