import React, { MutableRefObject, useRef } from "react";
import { createRoot, Root } from "react-dom/client";
import { __, Context } from "../../appcontext";
import css from "./BusyIndicator.scss";
import { Helper } from "../../common/common";

enum BusyIndicatorAlign {
  biaLeftTop,
  biaLeftMiddle,
  biaLeftBottom,
  biaCenterTop,
  biaCenter,
  biaCenterBottom,
  biaRightTop,
  biaRightMiddle,
  biaRightBottom,
}
enum BusyIndicatorType {
  bitInfinityRound,
  bitInfinityLine,
}
enum BusyIndicatorCursor {
  bicNormal,
  bicWait,
  biNotAllowed,
}
interface BusyIndicatorProps {
  visibility: boolean;
  type?: BusyIndicatorType;
  align?: BusyIndicatorAlign;
  backgroundShadow?: boolean;
  title?: string;
  message?: string;
  refreshBtn?: boolean; // Insurance action during long loading
  cursor?: BusyIndicatorCursor;
  children?: React.ReactNode;
  disableModal?: boolean;
  stopBtnRef?: MutableRefObject<HTMLButtonElement>;
}

export const K2BusyIndicator = (props: BusyIndicatorProps) => {
  const ref = useRef<HTMLDivElement>();

  function getModal() {
    if (props.disableModal != true && (props.title || props.message)) return "bi_modal";
    return "";
  }

  async function handleRefreshClick() {
    await Helper.sendErrorMessage("cancel reconnect in busy indicator", null);
    window.location.reload();
  }

  // Set cursor during loading
  if (props.visibility && props.cursor && ref.current) {
    switch (props.cursor) {
      case BusyIndicatorCursor.bicWait:
        ref.current.style.cursor = "wait";
        break;
      case BusyIndicatorCursor.biNotAllowed:
        ref.current.style.cursor = "not-allowed";
        break;
      default:
        ref.current.style.cursor = "unset";
    }
  }

  return (
    <>
      <div
        ref={ref}
        className={css.bi}
        data-visibility={props.visibility ? "1" : "0"}
        data-align={props.align === undefined ? BusyIndicatorAlign.biaCenter : props.align}
        data-background={props.backgroundShadow === undefined ? "1" : props.backgroundShadow ? "1" : "0"}
        data-type={props.type === undefined ? BusyIndicatorType.bitInfinityRound : props.type}
      >
        {(props.type === BusyIndicatorType.bitInfinityRound || props.type == undefined) && (
          <div className={`bi_wrap ${getModal()}`}>
            <div className="bi_loader">
              <div className="bi_children">{props.children}</div>

              <div className="bi_loading"></div>
            </div>

            {props.title && <span className="bi_title">{props.title}</span>}
            {props.message && <span className="bi_message">{props.message}</span>}
            {props.refreshBtn && (
              <button className="loginBtn" id="refreshBtn" onClick={handleRefreshClick}>
                {__("refresh")}
              </button>
            )}
            {props.stopBtnRef && (
              <button className="stopBtn" id="refreshBtn" ref={props.stopBtnRef}>
                {__("cancel")}
              </button>
            )}
          </div>
        )}
        {props.type === BusyIndicatorType.bitInfinityLine && (
          <div className="bi_wrap">
            <div className="bi_horizontal_loader"></div>
          </div>
        )}
      </div>
    </>
  );
};

interface SmallBusyIndicatorProps {
  visibility: boolean;
  align?: BusyIndicatorAlign;
  cursor?: BusyIndicatorCursor;
  children?: React.ReactNode;
}

export const K2SmallBusyIndicator = (props: SmallBusyIndicatorProps) => {
  // Set cursor during loading
  if (document.body.style.visibility && props.cursor) {
    switch (props.cursor) {
      case BusyIndicatorCursor.bicWait:
        document.body.style.cursor = "wait";
        break;
      case BusyIndicatorCursor.biNotAllowed:
        document.body.style.cursor = "not-allowed";
        break;
      default:
        document.body.style.cursor = "unset";
    }
  }

  return (
    <div className="sbi" data-visibility={props.visibility ? "1" : "0"} data-align={props.align == undefined ? BusyIndicatorAlign.biaLeftBottom : props.align}>
      <div className="sbi_content">
        {props.children === undefined && <img src="img/load.svg" width="22" height="22" />}
        {props.children && props.children}
      </div>
    </div>
  );
};

export default class BusyIndicatorProvider {
  private busyIndicatorStartTimer: any;
  private busyIndicatorStopTimer: any;
  private busyIndicatorCount = 0;
  private busyIndicatorStartFlag = false;
  private busyIndicatorStopFlag = false;
  private text = "";
  private busyIndicatorReloadTimer: any;
  private root: Root;
  private busyIndicatorStopBtn;
  private stopPostback: boolean;

  public props: BusyIndicatorProps;
  public smallProps: SmallBusyIndicatorProps;

  constructor() {
    this.busyIndicatorStopBtn = React.createRef<HTMLButtonElement>();
    this.props = {
      visibility: false,
      cursor: BusyIndicatorCursor.bicWait,
    };
    this.smallProps = {
      visibility: false,
      cursor: BusyIndicatorCursor.bicWait,
    };

    const el = document.createElement("div");
    el.className = "bi_global";
    document.body.appendChild(el);
    this.root = createRoot(el);

    this.render();
  }

  private render() {
    this.root.render(
      <>
        <K2BusyIndicator {...this.props} />
        <K2SmallBusyIndicator {...this.smallProps} />
      </>
    );
  }

  public canStopPostback(): boolean {
    return this.stopPostback;
  }

  public setPostbackProgress = (title: string, message: string): void => {
    if (this.busyIndicatorStopBtn) {
      if (this.busyIndicatorCount > 0) {
        this.props.children = <span className="bi_progress">{message + "%"}</span>;
        this.props.stopBtnRef = this.busyIndicatorStopBtn;
        this.setBigBusyIndicator(true, title, "");
        if (!this.busyIndicatorStopBtn.current.onclick) {
          this.busyIndicatorStopBtn.current.onclick = (e) => {
            this.stopPostback = true;
          };
        }
      }
    }
  };

  public setBusyIndicator = (showIndicator = true, title = "", message = "", forceBigIndicator = false, refreshBtn = false): void => {
    if (showIndicator) {
      clearTimeout(this.busyIndicatorStopTimer);
      this.busyIndicatorStopFlag = false;
      this.props.refreshBtn = refreshBtn;

      this.busyIndicatorCount++;
      if (forceBigIndicator) {
        this.setSmallBusyIndicator(true);
        this.setBigBusyIndicator(true, title, message);
      } else if (this.busyIndicatorCount === 1) {
        this.setSmallBusyIndicator(true);
        if (!this.busyIndicatorStartFlag) {
          this.busyIndicatorStartFlag = true;
          this.busyIndicatorStartTimer = setTimeout(() => {
            this.setBigBusyIndicator(true, title, message);
          }, 750);
        }
      }
      if (message !== "" && this.text !== message) {
        this.setBigBusyIndicator(true, title, message);
      }
    } else {
      this.busyIndicatorCount--;
      this.setSmallBusyIndicator(false);
      this.busyIndicatorStopFlag = true;
      this.busyIndicatorStopTimer = setTimeout(() => {
        clearTimeout(this.busyIndicatorStartTimer);
        this.busyIndicatorStartFlag = false;
        this.setBigBusyIndicator(false);
      }, 500);
    }
    this.render();
  };

  public setSmallBusyIndicator = (visible: boolean) => {
    if (visible) {
      this.smallProps.visibility = true;
    } else if (this.busyIndicatorCount === 0) {
      this.smallProps.visibility = false;
    }
    this.render();
  };

  public setBigBusyIndicator = (visible: boolean, titleT = "", messageT = "", refreshBtn = false) => {
    if (visible) {
      if (!this.busyIndicatorStopFlag) {
        this.props.visibility = true;
        this.props.refreshBtn = refreshBtn;

        if (Context.DeviceInfo.ShowReloadInBusyAfterTime > 0) {
          this.busyIndicatorReloadTimer = setTimeout(() => {
            clearTimeout(this.busyIndicatorReloadTimer);
            this.props.refreshBtn = true;
            this.render();
          }, Context.DeviceInfo.ShowReloadInBusyAfterTime * 1000);
        }

        if (this.props.title != "" || this.props.message !== "") {
          this.props.title = titleT;
          this.props.message = messageT;
          this.text = this.props.message;
        }
      }
    } else {
      clearTimeout(this.busyIndicatorReloadTimer);
      this.props.visibility = false;
      this.props.refreshBtn = false;
      this.props.refreshBtn = false;
      this.props.title = "";
      this.props.message = "";
      this.props.children = "";
      this.stopPostback = undefined;
    }
    this.render();
  };
}
