import React, { useRef, useState } from "react";
import { getAttributes } from "../../common/common";
import { Align, DataActionDecorate, IconPosition, UpdateCommandItem } from "../../common/communication.base";
import { ClientNclButton, NclButton, NclVirtualKeyboardDialog } from "../../common/components.ncl";
import { DecorateResult } from "../../common/visualContext";
import { WithVCXinCSSProps, writePropertyToCSS } from "../VCX/VCXHelper";
import { StyleHelper } from "./../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "./../Text/K2TruncateText";
import css from "./Button.scss";
import ReactResizeDetector from "react-resize-detector";
import SubMenuIndicator from "../SubMenuIndicator/K2SubMenuIndicator";
import { useServerState } from "../hooks";

export type BeforeExecuteProc = (control: NclButton) => void;

interface K2ButtonProps extends WithVCXinCSSProps {
  beforeExecute?: BeforeExecuteProc;
  id?: string;
  fillSvg?: boolean; //only for client button in virtual keyboard
}

export default function K2Button(props: K2ButtonProps) {
  const [control, data, element] = useServerState<NclButton, UpdateCommandItem, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclButton
  );
  const [accessKey, setAccessKey] = useState("");
  const focusedByTabKey = useRef(false);
  const actDec = getActualDecorate();
  let btnBaseCss = css.btn_base;
  let finId = control.MetaData.Name;

  function getContentCss() {
    let result: string = css.btn_content;

    if (control.MetaData.Bounds.Align === Align.Left || control.MetaData.Bounds.Align === Align.Right) result += " " + css.btn_padding;
    if (control.Ncl.FrgtData.FillWidth) result += " " + css.btn_content_fill;
    if (!control.State.Enabled) result += " " + css.btn_content_disabled;

    return result;
  }

  function getActualDecorate() {
    let result: DecorateResult;

    if (data.Enabled === true) {
      result = control.VCX.ColorMap.getColorsForActionDecorate(data.Decorate ? data.Decorate : control.Ncl.FrgtData.Decorate, data.Enabled);

      if (control.Ncl.BackgroundColorCalc >= 0) {
        result.backColor = control.VCX.getColor(control.Ncl.BackgroundColorCalc);
      }

      if (control.Ncl.ForegroundColorCalc >= 0) {
        result.foregroundColor = control.VCX.getColor(control.Ncl.ForegroundColorCalc);
      }
    } else {
      result = control.VCX.ColorMap.getColorsForActionDecorate(DataActionDecorate.dadeContentSuppress, data.Enabled);
    }

    return result;
  }

  function handleAccessKey(key: string) {
    setAccessKey(key);
  }

  function getContent(actDec: DecorateResult) {
    if (!data.GlyphId || !control.Ncl.FrgtData.ShowIcon) {
      return control.Ncl.FrgtData.ShowCaption ? (
        <div className={css.btn_caption_center} key={control.Ncl.ControlUID + "_txt"}>
          <K2TruncateText className={css.btn_label_font} setAccessKey={handleAccessKey}>
            {data.Title}
          </K2TruncateText>
        </div>
      ) : null;
    } else {
      if (!control.Ncl.FrgtData.ShowCaption) {
        return <K2Img glyphId={data.GlyphId} strokeColor={actDec.foregroundColor} vcx={control.VCX} nonSvgHeight={64} />;
      }

      let cssCaption = css.btn_caption_center;
      if (control.MetaData.Bounds.Align === Align.Left || control.MetaData.Bounds.Align === Align.Right) {
        cssCaption += " " + css.btn_caption_LorR;
      }

      switch (control.Ncl.FrgtData.IconPosition) {
        case IconPosition.ipBottom:
          return (
            <div className={css.btn_icon_top}>
              <div className={css.btn_caption_stretch}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={handleAccessKey}>
                  {data.Title}
                </K2TruncateText>
              </div>
              <K2Img glyphId={data.GlyphId} backgroundColor={actDec.backColor} strokeColor={actDec.foregroundColor} vcx={control.VCX} nonSvgHeight={64} />
            </div>
          );
        case IconPosition.ipTop:
          return (
            <div className={css.btn_icon_top}>
              <K2Img glyphId={data.GlyphId} backgroundColor={actDec.backColor} strokeColor={actDec.foregroundColor} vcx={control.VCX} nonSvgHeight={64} />
              <div className={css.btn_caption_stretch}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={handleAccessKey}>
                  {data.Title}
                </K2TruncateText>
              </div>
            </div>
          );
        case IconPosition.ipLeft:
          return (
            <>
              <ReactResizeDetector handleHeight>
                {({ height }) => (
                  <K2Img
                    glyphId={data.GlyphId}
                    backgroundColor={actDec.backColor}
                    strokeColor={actDec.foregroundColor}
                    vcx={control.VCX}
                    width={height}
                    nonSvgHeight={64}
                  />
                )}
              </ReactResizeDetector>
              <div className={cssCaption}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={handleAccessKey}>
                  {data.Title}
                </K2TruncateText>
              </div>
            </>
          );
        case IconPosition.ipRight:
          return (
            <>
              <div className={cssCaption}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={handleAccessKey}>
                  {data.Title}
                </K2TruncateText>
              </div>
              <ReactResizeDetector handleHeight>
                {({ height }) => (
                  <K2Img
                    glyphId={data.GlyphId}
                    backgroundColor={actDec.backColor}
                    strokeColor={actDec.foregroundColor}
                    vcx={control.VCX}
                    width={height}
                    nonSvgHeight={64}
                  />
                )}
              </ReactResizeDetector>
            </>
          );
        case IconPosition.ipCenter:
          return (
            <>
              <K2Img
                glyphId={data.GlyphId}
                strokeColor={actDec.foregroundColor}
                vcx={control.VCX}
                style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%", height: "100%" }}
                nonSvgHeight={64}
              />
              <div className={css.btn_caption_center}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={handleAccessKey}>
                  {data.Title}
                </K2TruncateText>
              </div>
            </>
          );
      }
    }
  }

  function execute() {
    if (props.beforeExecute) {
      props.beforeExecute(control);
    }

    control.executeCommand();
  }

  function handleClick() {
    if (control.Ncl.FrgtData.TabStop && focusedByTabKey.current) {
      focusedByTabKey.current = false;
      control.setActiveControlRequested();
    }

    execute();
  }

  function handleKeyUp(e: React.KeyboardEvent) {
    if (e.key === "Tab") {
      focusedByTabKey.current = true;
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      if (control instanceof ClientNclButton) return;

      execute();
      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === "ArrowRight") {
      (element.current?.parentElement?.parentElement?.nextElementSibling?.firstElementChild?.firstElementChild as HTMLDivElement)?.focus();
    } else if (e.key === "ArrowLeft") {
      (element.current?.parentElement?.parentElement?.previousElementSibling?.firstElementChild?.firstElementChild as HTMLDivElement)?.focus();
    }
  }

  function addAttributes(node: HTMLDivElement | null) {
    element.current = node;

    if (element.current) {
      writePropertyToCSS(actDec.backColor, "btn_background_color", element.current, "");
      writePropertyToCSS(actDec.foregroundColor, "btn_color", element.current, "");

      if (actDec.bold == true) writePropertyToCSS("bold", "btn_style", element.current, "");
    }
  }

  if (props.id != "" && props.id != undefined) {
    finId = props.id + finId;
  }

  if (control.Parent instanceof NclVirtualKeyboardDialog) btnBaseCss += " " + css.btn_VK_padding;

  return (
    <div style={StyleHelper(control, props.style)}>
      <div className={btnBaseCss}>
        <div
          ref={(node) => addAttributes(node)}
          data-k2-test-id={finId}
          className={`button ${getContentCss()}`}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          accessKey={accessKey}
          {...getAttributes(data)}
        >
          {getContent(actDec)}
          {data.SubMenuIndicatorVisible && <SubMenuIndicator strokeColor={actDec.foregroundColor} />}
        </div>
      </div>
    </div>
  );
}
