import React from "react";
import { UpdateControl } from "../../common/communication.base";
import { NclTabToolBar } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2ToolBar from "../ToolBar/K2ToolBar";
import css from "./TabToolBar.scss";

const K2TabToolBar = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclTabToolBar, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclTabToolBar
  );

  return (
    <div ref={element} style={StyleHelper(control, props.style)}>
      <div className={css.tt}>
        <K2ToolBar controlUID={control.ToolBars[0].MetaData.ControlUID} vrUID={props.vrUID} />
      </div>
    </div>
  );
};

export default K2TabToolBar;
